import React, { lazy, Suspense } from 'react';
import {
  Route,
  Routes,
  BrowserRouter,
  useLocation
} from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { SessionProvider } from "./contexts/SessionContext";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { ErrorBoundary } from "react-error-boundary";
import GeneralError from './pages/GeneralError';
import Auth from "./pages/Auth";
import GetStarted from './pages/GetStarted';
import SOS from "./pages/SOS";
import SessionResponseSpinner from './pages/shared/SessionResponseSpinner';
import LoadingSpinner from './pages/shared/LoadingSpinner';
import CalendarPage from "./pages/Calender";
import FAQ from "./pages/FAQ";
import Blogs from "./pages/Blogs";
import BookTherapy from './pages/BookTherapy';
import ViewTherapistProfile from './pages/ViewTherapistProfile';
import EditTherapistProfile from './pages/EditTherapistProfile';
import SuccessPage from './pages/BookSuccess';
import FailurePage from './pages/BookFailed';
import AdminView from './pages/AdminView';
import AddCompanyUsers from './pages/AddCompanyUsers';
import NotFound404 from './pages/NotFound404';
import TherapistView from './pages/TherapistView';
import ProtectedTherapistRoute from './ProtectedTherapistRoute';
import ProtectedUserRoute from './ProtectedUserRoute';
import ProtectedAuthRoute from './ProtectedAuthRoute';
import ProtectedAdminRoute from './ProtectedAdminRoute';
import OnboardingSuccessPage from './pages/OnBoardingSuccess';
import OnboardingPendingPage from './pages/OnBoardingPending';
import UserProfile from './pages/UserProfile';
import Plans from './pages/PlansIndv';
import AdminReport from './pages/AdminReport';
import CalmCorner from './pages/CalmCorner';
import Navbar from './pages/shared/Navbar';
import HomeNavbar from './pages/home/HomeNavbar';
import Footer from './pages/shared/Footer';
import HomeFooter from './pages/home/HomeFooter';
import CollectionPage from './pages/CollectionPage';
import MySession from './pages/session/MySession';
import SubscriptionSuccess from './pages/SubscriptionSuccess';
import SubscriptionFail from './pages/SubscriptionFail';
import ScrollToTop from './components/ScrollToTop';
import "./Google-Nunito.css";

library.add(fas, fab, far);

const Overview = lazy(() => import('./pages/session/Overview'))
const Analysis = lazy(() => import('./pages/Analysis'))
const DBTAdvice = lazy(() => import('./pages/DBTAdvice'))

const App = () => {
  return (
    <UserProvider>
      <SessionProvider>
        <BrowserRouter>
          <ErrorBoundary
            FallbackComponent={GeneralError}
            onError={(error) => console.error("Error occurred:", error)}
          >
            <ScrollToTop />
            <ContentWrapper />
          </ErrorBoundary>
        </BrowserRouter>
      </SessionProvider>
    </UserProvider>
  );
};

const ContentWrapper = () => {
  const location = useLocation();
  
  const hideNavbarAndFooter = ["/", "/auth" ].includes(location.pathname);

  return (
    <>
      {!hideNavbarAndFooter && <NavbarSwitcher />}
      <Routes>
        {/* Public routes */}
        <Route exact path="/" element={<Auth />} />
        <Route path="/auth" element={<Auth />} />

        {/* Protected routes */}
        <Route element={<ProtectedAuthRoute />}>
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/sos" element={<SOS />} />
          <Route path="/onboarding-success" element={<OnboardingSuccessPage />} />
          <Route path="/onboarding-pending" element={<OnboardingPendingPage />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/book-therapist" element={<BookTherapy />} />
          <Route path='/view-profile/:id' element={<ViewTherapistProfile />} />
          <Route path="/session" element={<MySession />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/calm-corner" element={<CalmCorner />} />

          <Route path="/subscription_success/:session_id" element={
            <Suspense fallback={<LoadingSpinner />}>
              <SubscriptionSuccess />
            </Suspense>
          } />
          <Route path="/overview" element={
            <Suspense fallback={<SessionResponseSpinner />}>
              <Overview />
            </Suspense>} />
          <Route path="/analysis" element={
            <Suspense fallback={<LoadingSpinner />}>
              <Analysis />
            </Suspense>} />
          <Route path="/dbt" element={
            <Suspense fallback={<LoadingSpinner />}>
              <DBTAdvice />
            </Suspense>} />
          <Route path="/mymood" element={<CalendarPage />} />

          {/* User-role protected routes */}
          <Route element={<ProtectedUserRoute />} >
            <Route path="/book-success/:therapist_id/:timeslot_id/:share_consent/:session_id" element={<SuccessPage />} />
            <Route path="/book-failure" element={<FailurePage />} />
            <Route path="/my-collection" element={<CollectionPage />} />
          </Route>

          {/* Therapist-role protected routes */}
          <Route element={<ProtectedTherapistRoute />} >
            <Route path="/therapist-view" element={<EditTherapistProfile />} />
            <Route path="/my-profile" element={<EditTherapistProfile />} />
          </Route>

          {/* Admin-role protected routes */}
          <Route element={<ProtectedAdminRoute />} >
            <Route path="/admin-view" element={<AdminView />} />
            <Route path="/add-employee" element={<AddCompanyUsers />} />
            <Route path="/wellbeing-report" element={<AdminReport />} />
          </Route>
        </Route>

        {/* 404 route */}
        <Route path="*" element={<NotFound404 />} />
      </Routes>
      
      {!hideNavbarAndFooter && <FooterSwitcher />}
    </>
  );
};

const NavbarSwitcher = () => {
  const location = useLocation();
  
  if (location.pathname === '/get-started') {
    return <HomeNavbar />;  
  }
  
  return <Navbar />;  
};

const FooterSwitcher = () => {
  const location = useLocation();
  
  if (location.pathname === '/get-started') {
    return <HomeFooter />;  
  }
  
  return <Footer />;  
};

export default App;
