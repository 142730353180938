import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';
import Dropdown from 'react-bootstrap/Dropdown';
import { getAuth, deleteUser } from "firebase/auth";
import Avatar from 'boring-avatars';
import { URL } from '../../Helpers';
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import { Typography, AppBar, Toolbar, Stack, Box, Button, Container, Grid, SvgIcon } from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import { ReactComponent as Home } from '../../data/home-icon.svg';
import { ReactComponent as MySessionWhite } from '../../data/mysession-white-icon.svg';
import { ReactComponent as MySessionBlue } from '../../data/mysession-blue-icon.svg';
import { ReactComponent as MyMood } from '../../data/mymood-icon.svg';
import { ReactComponent as Articles } from '../../data/blog-icon.svg';
import { ReactComponent as PokaTherapy } from '../../data/psychology.svg';
import { ReactComponent as FullWhite } from '../../data/fullwhite.svg';
import { ReactComponent as IconWhite } from '../../data/iconwhite.svg';
import { ReactComponent as HeartBlue } from '../../data/heart-blue.svg';
import { ReactComponent as HeartWhite } from '../../data/heart-white.svg';
import './HomeNavbar.scss'
import '../style.scss';
import UserDisplay from "./UserDisplay";

const HomeNavbar = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const navigate = useNavigate()
  const node = useRef();
  const auth = getAuth();
  const { user, role, tier, premium_status } = useUser();
  const [userName, setUserName] = useState('');
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const isSmallerThan1100px = useMediaQuery({ query: '(max-width: 1100px)' });
  const isMedium = useMediaQuery({ query: '(max-width: 1500px)' });

  const isMymoodPage = location.pathname === "/mymood";
  const isSessionPage = location.pathname === "/session";
  const isHomePage = location.pathname === "/get-started"
  const isBlogPage = location.pathname === "/blogs"
  const isTherapyPage = location.pathname === "/book-therapist"
  const isTherapist = location.pathname === "/therapist-view"
  const isCornerPage = location.pathname === "/calm-corner";


  // useEffect(() => {
  //   const fetchUserProfile = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await fetch(`${URL}/getProfile/${user.uid}`, {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${user.accessToken}`
  //         }
  //       });

  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }

  //       const data = await response.json();
  //       setUserName(data.displayName);
  //     } catch (err) {
  //       console.log("Error fetching name:", err);
  //       // Fallback to email or 'Guest' if fetch fails
  //       setUserName(user.email ? user.email.split('@')[0] : 'Guest');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchUserProfile();
  // }, [user.uid, user.email, user.accessToken]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setIsMenuOpen(false);
  };

  const handleDeleteAccount = async () => {
    const confirmation = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
    if (confirmation) {
      try {
        await deleteUser(auth.currentUser);
      } catch (error) {
        console.error('Error deleting account', error);
      }
    }
  };


  const userLink = role === 'user' && (
    <Button
      to="/book-therapist"
      component={RouterLink}
      sx={{
        m: 0,
        gap: "8px",
        "&:hover": {
          background: "none",
        }
      }}
    >
      <Box
        sx={{
          bgcolor: "#0e2d2f14",
          width: "48px",
          height: "48px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SvgIcon component={PokaTherapy} inheritViewBox sx={{ color: "secondary.superLightSand" }} />
      </Box>
      <Typography sx={{ color: "secondary.superLightSand" }} variant="body2Bold">MYTHERAPY</Typography>
    </Button>
  )

  const therapistLink = role === 'therapist' && (
    <Button
      to="/therapist-view"
      component={RouterLink}
      sx={{
        m: 0,
        gap: "8px",
        "&:hover": {
          background: "none",
        }
      }}
    >
      <Box
        sx={{
          bgcolor: "#0E2D2F",
          width: "48px",
          height: "48px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SvgIcon component={PokaTherapy} inheritViewBox sx={{ color: "secondary.superLightSand" }} />
      </Box>
      <Typography sx={{ color: "secondary.superLightSand" }} variant="body2Bold">MYTHERAPY</Typography>
    </Button>
  )

  useEffect(() => {
    // const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      setEmail(user.email);
    } else {
      setEmail(null);
    }
    // });

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // unsubscribe();
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <AppBar className="home-appbar" position="static" sx={{ bgcolor: "#5C83BF", justifyContent: "center" }} elevation={0} ref={node}>
      <Toolbar>
        {isMobile ? (
          <>
          <Box display={"flex"} height={"50px"} width={"350px"} overflow={"hidden"} alignItems={"center"} >
            <SvgIcon component={IconWhite} inheritViewBox style={{ width: "50px", height: "auto" }} />
            {/* <SvgIcon component={FullWhite} inheritViewBox style={{width:"125px", height:"120px"}}/> */}
            <Typography
              variant="sb24Bold"
              component={RouterLink}
              to="/get-started"
              sx={{ color: "white", textDecoration: 'none', paddingBottom: "5px" }}
            >
              pokamind
            </Typography>
            {/*<Typography variant="body2SemiBold" sx={{ pb: 5, flexGrow: 1, color: "primary.darkerBlue" }}>BETA</Typography> */}
          </Box>
          <Grid container sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} spacing={0}>
            <Typography
              variant="body2Bold"
              sx={{ pr: 2 }}
            >
        <UserDisplay user={user} />       
     </Typography>
            <FontAwesomeIcon
              icon="fa-solid fa-bars"
              size="xl"
              onClick={handleMenuToggle} />
            <CSSTransition
              in={isMenuOpen}
              timeout={300}
              classNames="mobile-menu-transition"
              unmountOnExit
            >
              <div className="mobile_menu">
                <Grid sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ pr: 3, pb: 3, display: "flex", justifyContent: "end" }}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-xmark"
                      size="xl"
                      style={{ color: '#ffffff' }}
                      onClick={handleMenuToggle} />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                      variant="body2Bold"
                      component="div"
                      alignItems={"center"}
                      sx={{ color: "secondary.superLightSand", display: "flex", alignItems: "center", pl: 2 }}
                    >
<UserDisplay user={user} />
                    </Typography>
                    <Dropdown>
                      <Dropdown.Toggle variant="text" id="dropdown-basic">
                        <Avatar name={email || 'default'} size={32} variant="beam" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {user ? (
                          <>
                            <Dropdown.Item aria-label="My Profile" onClick={() => navigate('/user-profile')}>MyProfile</Dropdown.Item>


                            <Dropdown.Item aria-label="Sign Out" onClick={() => auth.signOut().then(() => {
                              localStorage.removeItem('user');
                              localStorage.removeItem('role');
                              sessionStorage.clear();
                              navigate('/');
                            }
                            )}>Sign Out</Dropdown.Item>
                            <Dropdown.Item onClick={handleDeleteAccount}>Delete Account</Dropdown.Item>

                          </>
                        ) : (
                          <Dropdown.Item aria-label="Login" onClick={() => navigate('/auth')}>Login</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Box>
                  {// free tier with no premium_status (no subscription)
                    role !== "admin" && tier === "free" && !premium_status ?
                      (

                        <Button
                          to="/plans"
                          component={RouterLink}
                          sx={{
                            color: "secondary.main",
                            display: "block",
                            my: "5px"
                          }}
                        >
                          <Typography
                            variant="body2Bold"
                            component="div"
                            alignItems={"center"}
                            sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                          >

                            <div>TRY PREMIUM</div>
                          </Typography>
                        </Button>
                      ) :
                      // problem with premium_status like "overdue"
                      role !== "admin" && tier === "free" && premium_status ?
                        (
                          // <a href= className="no-style d-block hamburger-menu_links text-danger">
                          //   Fix subscription
                          // </a>
                          <Button
                            to={process.env.REACT_APP_StripeCustomerPortal + `?prefilled_email=${encodeURIComponent(user.email)}`}
                            component={RouterLink}
                            sx={{
                              color: "secondary.main",
                              display: "block",
                              my: "5px"
                            }}
                          >
                            <Typography
                              variant="body2Bold"
                              component="div"
                              alignItems={"center"}
                              sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                            >

                              <div>FIX SUBSCRIPTION</div>
                            </Typography>
                          </Button>
                        ) : (
                          // active subscription
                          role !== "admin" && tier === "premium" &&
                          // <a href={process.env.REACT_APP_StripeCustomerPortal + `?prefilled_email=${user.email}`} className="no-style d-block hamburger-menu_links">
                          //   Manage subscription
                          // </a>
                          <Button
                            component={RouterLink}
                            to={process.env.REACT_APP_StripeCustomerPortal + `?prefilled_email=${encodeURIComponent(user.email)}`}
                            sx={{
                              color: "secondary.main",
                              display: "block",
                              my: "5px"
                            }}
                          >
                            <Typography
                              variant="body2Bold"
                              component="div"
                              alignItems={"center"}
                              sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                            >

                              <div>MANAGE SUBSCRIPTION</div>
                            </Typography>
                          </Button>

                        )
                  }
                  {isHomePage ? (
                    <Button
                      component={RouterLink}
                      to="/get-started"
                      sx={{
                        color: "primary.main",
                        display: "block",
                        bgcolor: "secondary.main",
                        my: "10px",
                        mx: "5px"
                      }}
                    >
                      <Typography
                        variant="body2Bold"
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <SvgIcon
                          component={Home}
                          inheritViewBox />
                        <div>HOME</div>
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      component={RouterLink}
                      to="/get-started"
                      sx={{
                        color: "secondary.main",
                        display: "block",
                        my: "5px"
                      }}
                    >
                      <Typography
                        variant="body2Bold"
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <SvgIcon
                          component={Home}
                          inheritViewBox />
                        <div>HOME</div>
                      </Typography>
                    </Button>
                  )}
                  {isSessionPage ? (
                    <Button
                      component={RouterLink}
                      to="/session"
                      sx={{
                        display: "block",
                        color: "secondary.main",
                        "&:hover": {
                          color: "secondary.main",
                        },
                        bgcolor: "secondary.main",
                        my: "10px",
                        mx: "5px"
                      }}
                    >
                      <Typography
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <SvgIcon
                          component={MySessionBlue}
                          inheritViewBox />
                        <Typography component="div"
                          alignItems={"center"} variant="body2Bold" sx={{ color: "primary.main" }}>MYSESSION</Typography>
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      component={RouterLink}
                      to="/session"
                      sx={{
                        display: "block",
                        color: "primary.main",
                        "&:hover": {
                          color: "primary.main",
                        },
                        my: "5px"
                      }}
                    >
                      <Typography
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <SvgIcon
                          component={MySessionWhite}
                          inheritViewBox />
                        <Typography component="div"
                          alignItems={"center"} variant="body2Bold" sx={{ color: "secondary.main" }}>MYSESSION</Typography>
                      </Typography>
                    </Button>
                  )}
                  {isMymoodPage ? (
                    <Button
                      component={RouterLink}
                      to="/mymood"
                      sx={{
                        color: "primary.main",
                        display: "block",
                        bgcolor: "secondary.main",
                        my: "10px",
                        mx: "5px"
                      }}
                    >
                      <Typography
                        variant="body2Bold"
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <SvgIcon
                          component={MyMood}
                          inheritViewBox />
                        <div>MYMOOD</div>
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      component={RouterLink}
                      to="/mymood"
                      sx={{
                        color: "secondary.main",
                        display: "block",
                        my: "5px"
                      }}
                    >
                      <Typography
                        variant="body2Bold"
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <SvgIcon
                          component={MyMood}
                          inheritViewBox />
                        <div>MYMOOD</div>
                      </Typography>
                    </Button>
                  )}
                  {user && role === 'user' && (
                    isTherapyPage ? (
                      <Button
                        component={RouterLink}
                        to="/book-therapist"
                        sx={{
                          color: "primary.main",
                          display: "block",
                          bgcolor: "secondary.main",
                          my: "10px",
                          mx: "5px"
                        }}
                      >
                        <Typography
                          variant="body2Bold"
                          component="div"
                          alignItems={"center"}
                          sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                        >
                          <SvgIcon
                            component={PokaTherapy}
                            inheritViewBox />
                          <div>MYTHERAPY</div>
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        component={RouterLink}
                        to="/book-therapist"
                        sx={{
                          color: "secondary.main",
                          display: "block",
                          my: "5px"
                        }}
                      >
                        <Typography
                          variant="body2Bold"
                          component="div"
                          alignItems={"center"}
                          sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                        >
                          <SvgIcon
                            component={PokaTherapy}
                            inheritViewBox />
                          <div>MYTHERAPY</div>
                        </Typography>
                      </Button>
                    ))}
                  {user && role === 'therapist' && (
                    isTherapist ? (
                      <Button
                        component={RouterLink}
                        to="/therapist-view"
                        sx={{
                          color: "primary.main",
                          display: "block",
                          bgcolor: "secondary.main",
                          my: "10px",
                          mx: "5px"
                        }}
                      >
                        <Typography
                          variant="body2Bold"
                          component="div"
                          alignItems={"center"}
                          sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                        >
                          <SvgIcon
                            component={PokaTherapy}
                            inheritViewBox />
                          <div>MYTHERAPY</div>
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        component={RouterLink}
                        to="/therapist-view"
                        sx={{
                          color: "secondary.main",
                          display: "block",
                          my: "5px"
                        }}
                      >
                        <Typography
                          variant="body2Bold"
                          component="div"
                          alignItems={"center"}
                          sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                        >
                          <SvgIcon
                            component={PokaTherapy}
                            inheritViewBox />
                          <div>MYTHERAPY</div>
                        </Typography>
                      </Button>
                    ))}
                  {isBlogPage ? (
                    <Button
                      component={RouterLink}
                      to="/blogs"
                      sx={{
                        color: "primary.main",
                        display: "block",
                        bgcolor: "secondary.main",
                        my: "10px",
                        mx: "5px"
                      }}
                    >
                      <Typography
                        variant="body2Bold"
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <SvgIcon
                          component={Articles}
                          inheritViewBox />
                        <div>BLOGS</div>
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      component={RouterLink}
                      to="/blogs"
                      sx={{
                        color: "secondary.main",
                        display: "block",
                        my: "5px"
                      }}
                    >
                      <Typography
                        variant="body2Bold"
                        component="div"
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "end", gap: "6px" }}
                      >
                        <SvgIcon
                          component={Articles}
                          inheritViewBox />
                        <div>BLOGS</div>
                      </Typography>
                    </Button>
                  )}
                  <Button
                    component={RouterLink}
                    to="/sos"
                    sx={{ alignSelf: "end" }}
                  >
                    <Typography color="#FFA1A1" variant="sb16Bold" textAlign={"end"}>SOS</Typography>
                  </Button>

                </Grid>
              </div>
            </CSSTransition>
          </Grid>
          </>
        ) : (
          <Grid container flexDirection={"column"} justifyContent={"center"}>
            <Grid display={"flex"} justifyContent={"space-between"}>
              <Box display={"flex"} height={"50px"} width={"350px"} overflow={"hidden"} alignItems={"center"} >
                <SvgIcon component={IconWhite} inheritViewBox style={{ width: "50px", height: "auto" }} />
                {/* <SvgIcon component={FullWhite} inheritViewBox style={{width:"125px", height:"120px"}}/> */}
                <Typography
                  variant="sb24Bold"
                  component={RouterLink}
                  to="/get-started"
                  sx={{ color: "white", textDecoration: 'none', paddingBottom: "5px" }}
                >
                  pokamind
                </Typography>
                {/*<Typography variant="body2SemiBold" sx={{ pb: 5, flexGrow: 1, color: "primary.darkerBlue" }}>BETA</Typography> */}
              </Box>
              <Grid item className="navbar-dropdown" lg={3} md={5} sm={6} xs={6}>
            <Button
              component={RouterLink}
              to="/sos"
            >
              <Typography color="#FFA1A1" variant="sb16Bold" textAlign={"end"}>SOS</Typography>
            </Button>
            <div className="vertical-line" style={{ background: "#E3DBD5", height: "2rem", alignSelf:"center" }}></div>
            {// free tier with no premium_status (no subscription)
              role !== "admin" && tier === "free" && !premium_status ?
                (

                  <Button
                    component={RouterLink}
                    to="/plans"
                    sx={{
                      m: 0,
                      gap: "8px",
                      "&:hover": {
                        background: "none",
                      }
                    }}
                  >


                    <Typography sx={{ color: "secondary.superLightSand" }} variant="body2Bold">TRY PREMIUM</Typography>
                  </Button>
                ) :
                // problem with premium_status like "overdue"
                role !== "admin" && tier === "free" && premium_status ?
                  (
                    // <a href= className="no-style d-block hamburger-menu_links text-danger">
                    //   Fix subscription
                    // </a>
                    <Button
                      component={RouterLink}
                      to={process.env.REACT_APP_StripeCustomerPortal + `?prefilled_email=${encodeURIComponent(user.email)}`}
                      sx={{
                        m: 0,
                        gap: "8px",
                        "&:hover": {
                          background: "none",
                        }
                      }}
                    >
                      <Typography
                        sx={{ color: "#FFA1A1" }}
                        variant="body2Bold">FIX SUBSCRIPTION</Typography>

                    </Button>
                  ) : (
                    // active subscription
                    role !== "admin" && tier === "premium" &&
                    // <a href={process.env.REACT_APP_StripeCustomerPortal + `?prefilled_email=${user.email}`} className="no-style d-block hamburger-menu_links">
                    //   Manage subscription
                    // </a>
                    <Button
                      component={RouterLink}
                      to={process.env.REACT_APP_StripeCustomerPortal + `?prefilled_email=${encodeURIComponent(user.email)}`}
                      sx={{
                        m: 0,
                        gap: "8px",
                        "&:hover": {
                          background: "none",
                        }
                      }}
                    >
                      <Typography
                        sx={{ color: "secondary.superLightSand" }}
                        variant="body2Bold">MANAGE SUBSCRIPTION</Typography>

                    </Button>

                  )
            }
            {!isSmallerThan1100px && (
              <Typography
                variant="body2Bold"
                component="div"
                sx={{ color: "secondary.superLightSand", display: "flex", alignItems: "center", pl: 2 }}
              >
<UserDisplay user={user} />
              </Typography>
            )}
            <Dropdown>
              <Dropdown.Toggle variant="text" id="dropdown-basic">
                <Avatar name={email || 'default'} size={32} variant="beam" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {user ? (
                  <>
                    <Dropdown.Item aria-label="My Profile" onClick={() => navigate('/user-profile')}>MyProfile</Dropdown.Item>


                    <Dropdown.Item aria-label="Sign Out" onClick={() => auth.signOut().then(() => {
                      localStorage.removeItem('user');
                      localStorage.removeItem('role');
                      sessionStorage.clear();
                      navigate('/');
                    }
                    )}>Sign Out</Dropdown.Item>
                    <Dropdown.Item onClick={handleDeleteAccount}>Delete Account</Dropdown.Item>
                  </>
                ) : (
                  <Dropdown.Item aria-label="Login" onClick={() => navigate('/auth')}>Login</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Grid>
            </Grid>
            <Grid container width={"fit-content"} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} position={isMedium ? "unset" : "absolute"} alignSelf={"center"}>
              <Button
                to="/get-started"
                component={RouterLink}
                variant="homeHeader"
              >
                <SvgIcon sx={{ color: "primary.darkerBlue" }} component={Home} inheritViewBox />
                <Typography sx={{ color: "primary.darkerBlue" }} variant="body2Bold">HOME</Typography>
              </Button>
              <Button
                to="/session"
                component={RouterLink}
                sx={{
                  m: 0,
                  gap: "8px",
                  color: "primary.backgroundBlue",
                  "&:hover": {
                    color: "primary.backgroundBlue",
                    background: "none"
                  }
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#0e2d2f14",
                    width: "48px",
                    height: "48px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SvgIcon component={MySessionWhite} inheritViewBox />
                </Box>
                <Typography variant="body2Bold" sx={{ color: "secondary.superLightSand" }} >MYSESSION</Typography>
              </Button>
              <Button
                to="/mymood"
                component={RouterLink}
                sx={{
                  m: 0,
                  gap: "8px",
                  "&:hover": {
                    background: "none",
                  }
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#0e2d2f14",
                    width: "48px",
                    height: "48px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SvgIcon component={MyMood} inheritViewBox sx={{ color: "secondary.superLightSand" }} />
                </Box>
                <Typography sx={{ color: "secondary.superLightSand" }} variant="body2Bold" >MYMOOD</Typography>
              </Button>
              {userLink} {therapistLink}
              <Button
                to="/blogs"
                component={RouterLink}
                sx={{
                  m: 0,
                  gap: "8px",
                  "&:hover": {
                    background: "none",
                  }
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#0e2d2f14",
                    width: "48px",
                    height: "48px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SvgIcon component={Articles} inheritViewBox sx={{ color: "secondary.superLightSand" }} />
                </Box>
                <Typography sx={{ color: "secondary.superLightSand" }} variant="body2Bold">BLOGS</Typography>
              </Button>

              <Button
                to="/calm-corner"
                component={RouterLink}
                sx={{
                  m: 0,
                  gap: "8px",
                  "&:hover": {
                    background: "none",
                  }
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#0e2d2f14",
                    width: "48px",
                    height: "48px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SvgIcon component={HeartWhite} inheritViewBox sx={{ color: "secondary.superLightSand" }} />
                </Box>
                <Typography sx={{ color: "secondary.superLightSand" }} variant="body2Bold">CALM CORNER</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Toolbar>

    </AppBar>
  );
};

export default HomeNavbar;