import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { collection, getDocs, limit, query } from 'firebase/firestore';
import { Typography, Button, Box, Tooltip, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, keyframes } from '@mui/system';
import HomeCarousel from './HomeCarousel';
import { useUser } from '../../contexts/UserContext';
import { URL } from '../../Helpers';
import { db } from '../../Firebase';

import './HomePage.scss';
import circle from "../../data/circle.png";
import mic from "../../data/mic.svg";
import video from "../../data/videocam.svg";
import articles from "../../data/article.svg";
import { ReactComponent as Volume } from '../../data/graphic_eq.svg';
import { ReactComponent as AdminIcon } from '../../data/admin_icon.svg'; // Assuming you have an admin icon
import UserDisplay from './UserDisplay';

const waveAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
`;

const WavingTooltipContent = styled(Box)`
  animation: ${waveAnimation} 2s ease-in-out infinite;
`;

const customStyles = `
  .css-ezc7lx-MuiTooltip-tooltip {
    background-color: transparent !important;
  }
  .MuiTooltip-arrow {
    color: #4C6EA2;
    width: 20px;
    height: 15px;
    margin-top: -1.5em !important;
  }
`;

const HomePage = () => {
    const navigate = useNavigate();
    const [greeting, setGreeting] = useState("");
    const [email, setEmail] = useState(null);
    const { user,role } = useUser();
    const [showTooltip, setShowTooltip] = useState(false);
    const isMax768px = useMediaQuery('(max-width: 450px)');
    const [userName, setUserName] = useState('');

    useEffect(() => {

        const currentHour = new Date().getHours();
        if (currentHour >= 6 && currentHour < 12) {
            setGreeting("Good morning");
        } else if (currentHour >= 12 && currentHour < 18) {
            setGreeting("Good afternoon");
        } else if (currentHour >= 18 && currentHour < 24) {
            setGreeting("Good evening");
        } else {
            setGreeting("Good night");
        }

        if (user) {
            setEmail(user.email);
            fetchUserCalendar();
            fetchUserProfile();
        } else {
            setEmail(null);
        }
    }, [user]);
    const fetchUserCalendar = async () => {
        try {
            const q = query(collection(db, "users", user.uid, "sessions"), limit(1));
            const response = await getDocs(q);
            if (response.empty) setShowTooltip(true);
        } catch (error) {
            console.error("Error fetching user calendar:", error);
        }
    };

    const fetchUserProfile = async () => {
        try {
            const response = await fetch(`${URL}/getProfile/${user.uid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`
                }
            });

            if (!response.ok) throw new Error('Network response was not ok');

            const data = await response.json();
            setUserName(data.displayName);
        } catch (err) {
            console.log("Error fetching name:", err);
        }
    };

    const renderActionButton = () => {
        if (role === "admin") {
            return (
                <Button to="/admin-view" component={RouterLink}>
                    <Box
                        alignItems="center"
                        display="flex"
                        gap="12px"
                        padding="10px 26px 10px 22px"
                        bgcolor="#FFF7F1"
                        borderRadius="10px"
                        border="2.5px solid #4C6EA2"
                    >
                        <AdminIcon sx={{ width: "36px", height: "36px", paddingLeft: "3px" }} />
                        <Typography color="#4C6EA2" variant="sb24Bold">
                            Admin Dashboard
                        </Typography>
                    </Box>
                </Button>
            );
        }

        return (
            <Tooltip
                open={showTooltip}
                title={
                    <WavingTooltipContent
                        bgcolor="#FFF7F1"
                        border="2.5px solid #4C6EA2"
                        borderRadius="10px"
                        p="10px"
                        width="200px"
                        textAlign="center"
                    >
                        <Typography color="#4C6EA2" variant="body1Medium">
                            Let's get a session started!
                        </Typography>
                    </WavingTooltipContent>
                }
                placement="bottom"
                arrow
            >
                <Button to="/session" component={RouterLink}>
                    <Box
                        alignItems="center"
                        display="flex"
                        gap="12px"
                        padding="10px 26px 10px 22px"
                        bgcolor="#FFF7F1"
                        borderRadius="10px"
                        border="2.5px solid #4C6EA2"
                    >
                        <Volume sx={{ width: "36px", height: "36px", paddingLeft: "3px" }} />
                        <Typography color="#4C6EA2" variant="sb24Bold">
                            Start Session
                        </Typography>
                    </Box>
                </Button>
            </Tooltip>
        );
    };

    const renderDescription = () => {
        if (role === "admin") {
            return (
                <Typography variant='sb24Medium'>
                    Access the admin dashboard to view aggregated and anonymous data on employee wellbeing. 
                    You'll find graphs, metrics, and a comprehensive written report. 
                    You can also manage employee emails for platform tracking.
                </Typography>
            );
        }

        return (
            <Typography variant='sb24Medium'>
                Do a 3-minute anonymous session and talk about anything on your mind. 
                Our AI will read your communication styles and provide you with advice.
            </Typography>
        );
    };

    return (
        <div className="prevent_overflow">
            <Grid container className="greeting-section" style={{ height: (showTooltip && isMax768px) ? '54rem' : '' }}>
                <Grid md={6} sm={6} lg={6} xs={12} textAlign={'center'} className='home-images'>
                    <img src={circle} alt="circles" className='home-circles' />
                    <div style={{ position: "relative", zIndex: "+1", height: "0" }}>
                        <img src={mic} alt="mic" className='circles-mic' />
                        <img src={video} alt="videocam" className='circles-video' />
                        <img src={articles} alt="article" className='circles-articles' />
                    </div>
                </Grid>
                <Grid md={5} sm={6} lg={5} xs={10} display={'flex'} flexDirection={'column'} alignItems={'start'} className='greeting-part' style={{ color: "white" }}>
                    <Typography variant='h42SemiBold'>
                        {greeting}, <UserDisplay user={user} />

                    </Typography>
                    {renderDescription()}
                    <div className='d-flex align-items-center greeting-part mt-4' style={{ position: "relative" }}>
                        <style>{customStyles}</style>
                        {renderActionButton()}
                    </div>
                </Grid>
            </Grid>
            <Grid className='home-slide' container alignItems={'center'} display={'flex'} flexDirection={'column'}>
                <Typography variant='h36Bold' color={"#4C6481"} mt={4}>
                    Weekly Milestones
                </Typography>
                <HomeCarousel />
            </Grid>
        </div>
    );
};

export default HomePage;