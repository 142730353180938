import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useSession } from '../../contexts/SessionContext';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { useErrorBoundary } from "react-error-boundary";
import { db, doc, onSnapshot, getDoc } from "../../Firebase";
import { URL, getDateString } from "../../Helpers";
import Recorder from 'recorder-js';
import Webcam from 'react-webcam';
import audiofile from '../../start.wav';
import audioending from '../../end.wav';
import LoadingSpinner from "../shared/LoadingSpinner";
import SessionResponseSpinner from "../shared/SessionResponseSpinner";
import { v4 as uuid4 } from 'uuid';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, SvgIcon, Box, Button } from "@mui/material";
import { ReactComponent as Ellipse2 } from '../../data/Ellipse2.svg';
import { ReactComponent as ArrowForward } from '../../data/arrow_forward.svg';
import { ReactComponent as ArrowBack } from '../../data/arrow_back.svg';
import { ReactComponent as videoIcon } from '../../data/videoIcon.svg';
import { ReactComponent as stopCircle } from '../../data/stop_circle.svg';
import Relationship from '../../data/relationship.png';
import Work from '../../data/worktopic.png';
import Stress from '../../data/gratitude.png';
import Other from '../../data/othertopic.png';
import MediaTest from "./MediaTest";
import Avatar from '../../data/avatar.png';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MySession.scss"
import { useMediaQuery } from 'react-responsive';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

// Configuration for tier-specific limits
const tierLimits = {
  free: {
    weeklyLimit: 3,  // Indicates the limit of 3 sessions a week
    dailyLimit: true, // Indicates the limit of 1 session a day
    SESSION_TIMER: 180 // Indicates the duration of 3 minutes
  },
  premium: {
    weeklyLimit: 12,  // Indicates the limit of 12 sessions a week
    dailyLimit: false, // Indicates the limit of 1 session a day is not applied
    durationToStart: 180, // Indicates the minimum duration to start a session 3 minutes
    dailyDurationLimit: 600, // Indicates the maximum duration for a session 10 minutes
    SESSION_TIMER: 600 // Indicates the duration of 10 minutes
  }
};


const MySession = () => {
  const navigate = useNavigate();
  const { overviewData, setOverviewData, setSessionContextDate } = useSession();
  const [recording, setRecording] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false)
  const [question, setQuestion] = useState('');
  const [showQuestions, setShowQuestions] = useState(false);
  const [webcamVisible, setWebcamVisible] = useState(false);
  const [videoAccess, setVideoAccess] = useState(true);
  const [resultError, setresultError] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState("Formulating your first question...");
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showInstructions, setShowInstructions] = useState(true);
  const [showWarning, setShowWarning] = useState(false)
  const [WarningMessage, setWarningMessage] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [microphone, setMicrophone] = useState(true);
  // Limit reached 
  const [limitError, setLimitError] = useState(false);
  const topics = ['Relationships', 'Work', 'Stress', 'Other'];
  const topicImages = {
    Relationships: Relationship,
    Work: Work,
    Stress: Stress,
    Other: Other
  };
  const [showAvatar, setShowAvatar] = useState(true);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const { user, role, tier } = useUser();
  const { showBoundary } = useErrorBoundary();
  const audioStreamRef = useRef(null)
  const tempDirRef = useRef(null)
  const imgTimeoutIDRef = useRef(null)
  const audioTimeoutIDRef = useRef(null)
  const audioContextRef = useRef();
  const camRef = useRef(null);
  const audioRef = useRef(null);
  const audioCanvasRef = useRef(null);
  const micCanvasRef = useRef(null);
  const canvasParentRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const barWidthRef = useRef(null);
  const countdownIntervalRef = useRef();
  const timeoutRef = useRef()
  const audioRecorderRef = useRef()
  const audioEndingRef = useRef()
  const sessionDate = useRef(getDateString())
  // generate a unique session id uuid
  const sessionIDUnique = useRef(uuid4())
  const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });

  // SESSION_TIMER pick based on the tier
  let SESSION_TIMER = useMemo(() => tierLimits[tier].SESSION_TIMER, [tier])

  const checkSessions = useCallback(async (tier) => {
    const userRef = doc(db, "users", user.uid);
    const todayStr = new Date().toISOString().split('T')[0]; // Format: 'YYYY-MM-DD'
    const weekStart = new Date();
    weekStart.setDate(weekStart.getDate() - weekStart.getDay() + (weekStart.getDay() === 0 ? -6 : 1)); // Adjust to start of this week, considering Sunday as the start of a new week
    const weekStartStr = weekStart.toISOString().split('T')[0];
    const weeklyLimit = tierLimits[tier].weeklyLimit;
    const dailyLimit = tierLimits[tier].dailyLimit;
    const durationToStart = tierLimits[tier].durationToStart;
    const dailyDurationLimit = tierLimits[tier].dailyDurationLimit;

    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        const sessionCount = data.session_count || {};
        const thisWeek = sessionCount.this_week || {};
        const thisDay = sessionCount.this_day || {};
        const sessionsThisWeek = thisWeek.count || 0;
        const sessionToday = thisWeek.sessions && thisWeek.sessions.includes(todayStr);

        if (weekStartStr !== thisWeek.week_start) {
          console.log("You can start a new session.");
          return { allowSession: true };
        }
        // Check session limits based on the role
        if (sessionsThisWeek >= weeklyLimit) {
          console.log("You have reached the limit of sessions for the week.");
          return {
            allowSession: false, reason: "weekly limit reached",
            message: "You have reached the limit of sessions for the Week. This will reset on Monday."
          };
        } else if (role === 'premium') {
          // For premium users, check if the total duration of sessions for the day is less than the dailyDurationLimit
          const totalDurationToday = thisDay.totalDuration || 0; // Assuming this is stored in the database

          if (thisDay.date && todayStr === thisDay.date &&
            totalDurationToday >= dailyDurationLimit) {
            console.log("You have reached the daily duration limit.");
            return {
              allowSession: false, reason: "daily duration limit reached",
              message: "You have reached the daily duration limit. This limit will reset tomorrow."
            };
          } else if (thisDay.date && todayStr === thisDay.date &&
            dailyDurationLimit - totalDurationToday < durationToStart) {
            console.log("Session duration is less than the minimum required to start a session.");
            return {
              allowSession: false, reason: "duration to start not met",
              message: "The remaining daily session duration is less than the minimum required to start a session."
            };
          } else {
            console.log("You can start a new session.");
            //set the session timer as the time remaining for premium user
            SESSION_TIMER = dailyDurationLimit - totalDurationToday
            return { allowSession: true };
          }
        } else if (dailyLimit && sessionToday) {
          console.log("You have already had a session today.");
          return {
            allowSession: false, reason: "daily limit reached",
            message: "You have reached the limit of sessions for the day. Please try again tomorrow."
          };
        } else {
          console.log("You can start a new session.");
          return { allowSession: true };
        }
      } else {
        console.error("User document does not exist.");
        showBoundary(new Error("User not found"))
        // return { message: "User not found" };
      }
    } catch (error) {
      console.error("Error fetching user session data: ", error);
      showBoundary(new Error("Error fetching data"))

      // return { message: "Error fetching data" };
    }
  }, [user, role]);


  const handleTopicClick = useCallback(async (topic) => {

    checkSessions(tier || "free").then(result => {
      if (result.allowSession) {
        // Proceed with starting a new session
        setSelectedTopic(topic);

      } else {
        // Show an appropriate message to the user based on result.reason
        setLimitError({ reason: result.reason, message: result.message })


      }
    });
  }, [checkSessions, user])

  // Function to close the limit reached modal
  const handleCloseLimitReachedModal = () => {
    setLimitError(false)


  };

  const showAlert = () => {
    alert('Button clicked!');
  };

  // Update mic canvas size on window resize
  useEffect(() => {
    const updateCanvasSize = () => {

      if (!canvasParentRef.current || !micCanvasRef.current) return;
      micCanvasRef.current.width = canvasParentRef.current.offsetWidth;
      micCanvasRef.current.height = canvasParentRef.current.offsetHeight;
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);

    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [recording]);

  useEffect(() => {

    if (resultError) {
      console.log('result error!')
      setOverviewData(null)
      setRecording(false)
      setCountdown(null)
      setLoading(false)
      setQuestion('')
      setShowQuestions(false)
      setSelectedTopic(false)
      setWebcamVisible(false)
      setMicrophone(false)
      tempDirRef.current = null
      // Cleanup function for audio stream and recorder
      if (audioRecorderRef.current) {
        audioRecorderRef.current.stop(); // Stop the recorder
        audioRecorderRef.current = null; // Release the recorder
      }
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach((track) => {
          track.stop(); // Stop all the tracks in the received media stream
        });
        audioStreamRef.current = null; // Release the media stream
      }
      // If you're using an AudioContext, close it to release resources
      if (audioContextRef.current?.state === 'running') {
        audioContextRef.current.close();
      }
      clearTimeout(timeoutRef.current)

      setResponseLoading(false)
      setWarningMessage(null)
      setShowWarning(false)
      if (imgTimeoutIDRef.current)
        clearTimeout(imgTimeoutIDRef.current)
      if (audioTimeoutIDRef.current)
        clearTimeout(audioTimeoutIDRef.current)
    }
    return () => {


      // const [resultError, setresultError] = useState(false);
      // console.log('unmountin???',user)
    }
  }, [resultError, setOverviewData])

  useEffect(() => {
    document.title = "MySession"
    return () => {
      // console.log('exiting session')
      // setResult(null)
      setRecording(false)
      setCountdown(null)
      setLoading(false)
      setQuestion('')
      setShowQuestions(false)
      setWebcamVisible(false)
      setresultError(false)
      tempDirRef.current = null
      // Cleanup function for audio stream and recorder
      if (audioRecorderRef.current) {
        audioRecorderRef.current.stop(); // Stop the recorder
        audioRecorderRef.current = null; // Release the recorder
      }
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach((track) => {
          track.stop(); // Stop all the tracks in the received media stream
        });
        audioStreamRef.current = null; // Release the media stream
      }
      // If you're using an AudioContext, close it to release resources
      if (audioContextRef.current?.state === 'running') {
        audioContextRef.current.close();
      }
      clearTimeout(timeoutRef.current)
      setResponseLoading(false)
      setWarningMessage(null)
      setShowWarning(false)
      if (imgTimeoutIDRef.current)
        clearTimeout(imgTimeoutIDRef.current)
      if (audioTimeoutIDRef.current)
        clearTimeout(audioTimeoutIDRef.current)

      // const [resultError, setresultError] = useState(false);
      // console.log('unmountin???',user)

      //when going to overview remove the uniqueID state from history
      //to prevent users rerunning process of dbt and analysis on clicking back
      window.history.replaceState({}, "")
    }
  }, [])

  const startCountdown = useCallback((duration) => {
    let remainingTime = duration;

    setCountdown(remainingTime);

    setTimeout(() => {
      countdownIntervalRef.current = setInterval(() => {
        remainingTime -= 1;

        // Play ending audio before end
        if (audioEndingRef.current && remainingTime === Math.floor(audioEndingRef.current.duration) + 2) {
          // audioEndingRef.current.play()
          startVisualizer(audioCanvasRef.current, audioEndingRef.current);
        }

        if (remainingTime <= 0) {
          setCountdown(null);
          setQuestion(null);
          clearInterval(countdownIntervalRef.current);
        } else {
          setCountdown(remainingTime);
        }
      }, 1000);
    }, 8000); // 8000 milliseconds = 8 seconds
  }, [setCountdown]);

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };



  //upload Image
  const uploadData = async (data, url) => {
    try {
      const response = await fetch(url, {
        method: 'POST',

        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
          // 'Access-Control-Request-Method': 'POST',
          // 'Access-Control-Request-Headers': 'Content-Type',
          // 'Origin': 'https://127.0.0.1:3000'
        },
        body: data,
        // credentials: 'include',
      });

      if (!response.ok) {
        handleWarning("Face not detected.")
        let msg = await response.json()
        throw new Error('Network response was not ok ' + msg.message);
      }

      const responseData = await response.json();
      // console.log('image response: ', responseData);
    } catch (error) {
      // showBoundary(error)
      console.error(error);
    }
  };



  const startVisualizer = useCallback((audiocanvas, audiofile = null) => {
    const canvas = audiocanvas;
    const ctx = canvas.getContext("2d");
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioSource = audiofile ? audioContext.createMediaElementSource(audiofile)
      : audioContext.createMediaStreamSource(audioStreamRef.current);
    const analyser = audioContext.createAnalyser();

    audioSource.connect(analyser);
    //if the audio being visualized is an audiofile then playback to the user
    if (audiofile) {
      audiofile.play();
      analyser.connect(audioContext.destination);
    }

    analyser.fftSize = 512;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const circleCount = 3;
    const baseRadius = parseInt(canvas.height / 4.5);
    let lastRadii = new Array(circleCount).fill(baseRadius);
    let opacity = 1;
    const colorBase = audiofile ? { r: 59, g: 130, b: 246 } : { r: 255, g: 161, b: 84 };

    function lerp(start, end, amt) {
      return (1 - amt) * start + amt * end;
    }

    function animate() {
      requestAnimationFrame(animate);
      analyser.getByteFrequencyData(dataArray);

      let sum = dataArray.reduce((a, b) => a + b, 0);
      let average = sum / bufferLength;
      let normAverage = average / 128;
      if (average === 0) {
        canvas.style.visibility = "hidden"; // Hide the canvas when no audio is detected
      } else {
        canvas.style.visibility = "visible"; // Show the canvas when audio is detected
      }

      // Drawing logic
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const scalingFactors = [1, 1.5, 4];
      const opacityLevels = [0.8, 0.6, 0.4];
      const borderThicknesses = [5, 4, 3];

      for (let i = 0; i < circleCount; i++) {
        // Increase sensitivity by using a larger scaling factor
        let scaledAverage = Math.pow(normAverage, 2) * scalingFactors[i];

        // Calculate the maximum radius based on the canvas dimensions
        const maxRadius = Math.min(canvas.width, canvas.height) / 2;
        // Ensure the target radius does not exceed the maximum radius
        const targetRadius = Math.min(baseRadius + scaledAverage * baseRadius * 2, maxRadius);

        // Smoother transition for a more fluid animation
        lastRadii[i] = lerp(lastRadii[i], targetRadius, 0.3);

        // Position circles closer to each other
        const x = canvas.width / 2;
        const y = canvas.height / 2;
        ctx.lineWidth = borderThicknesses[i];

        ctx.beginPath();
        ctx.arc(x, y, lastRadii[i], 0, Math.PI * 2, false);
        ctx.strokeStyle = `rgba(76, 110, 162, ${opacityLevels[i]})`; // Set border color
        ctx.fillStyle = `rgba(${colorBase.r}, ${colorBase.g}, ${colorBase.b}, 0)`; // Transparent background
        ctx.fill();
        ctx.stroke();
      }
    }


    animate();
  }, []);



  const toggleWebcam = async () => {
    setWebcamVisible((prevVisible) => !prevVisible);
  };

  const startRecordingAudio = useCallback(async (stream) => {
    audioRecorderRef.current = new Recorder(audioContextRef.current, { numChannels: 1 });
    await audioRecorderRef.current.init(stream);
    audioRecorderRef.current.start().then((data) => {
      console.log('Audio recording started');
    }).catch(err => (err));
  }, []);

  const createSessionDir = async () => {
    //changed to await to wait for session
    try {
      const response = await fetch(URL + '/session_dir/' + sessionDate.current, {
        headers: {
          'Authorization': `Bearer ${user.accessToken}`
        },
        method: 'GET',
        cache: 'no-cache'
        // credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok',);
      }

      const data = await response.json();
      // console.log(data);
      tempDirRef.current = data.session_Dir_ID;
      return true
    } catch (error) {
      showBoundary(new Error("Could not create session directory, " + error))
      return false
      // console.error('Error:', error);
      // throw new Error('Error fetching sessionDir: ', error)
    }
  }

  //if audio or video error during session
  const handleWarning = useCallback((message) => {
    setShowWarning(true);
    setWarningMessage(message)
    setTimeout(() => {
      setShowWarning(false);
      setWarningMessage(null);
    }, 1500);
  }, []);


  // Send audio every timeout duration
  const sendAudio = () => {
    console.log('Gathering Audio')
    audioRecorderRef.current.stop().then(({ blob }) => {
      // console.log('audio blob', blob);
      const formData = new FormData();
      formData.append('audio', blob, `audio.wav`);
      // replacing uploaddata method with using fetch here
      fetch(URL + '/upload_audio/' + sessionDate.current + '/' + tempDirRef.current, {
        headers: {
          'Authorization': `Bearer ${user.accessToken}`
        },
        method: 'POST',
        cache: 'no-cache',
        body: formData,
        // credentials: "include",
      })
        .then((response) => {
          if (!response.ok) {
            handleWarning("Voice not detected")
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setQuestion(data.message);
        })
        .catch((error) => {
          // showBoundary(error)
          console.error(error);
        });
      // when finished clear audio data and listen again
      audioRecorderRef.current.start();
    });
    //settimeout to loop over the function
    //save the id of timeout to stop it later
    audioTimeoutIDRef.current = setTimeout(sendAudio, 20000)
    // console.log('current audio', audioTimeoutIDRef.current)
    // console.log('audio id', audioTimeoutIDRef)
  }

  //load the timer once audio file is loaded
  const loadVisualizer = () => {
    // setCountdown(formatTime(audioRef.current.duration));
    // startCountdown(Math.floor(audioRef.current.duration));
    // console.log(audioRef.current.duration)
    setCountdown(SESSION_TIMER);
    startCountdown(SESSION_TIMER);
    startVisualizer(audioCanvasRef.current, audioRef.current);
  }

  const startRecording = async () => {
    //if getting back to session and starting again, clear prev result
    setOverviewData(null)
    //if analysis was unclear and restarting
    if (resultError)
      setresultError(false)
    if (!navigator.mediaDevices) {
      console.error('getUserMedia not supported.');
      return;
    }
    // initializeAudioContext();
    setLoading(true)
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(async (stream) => {

        audioStreamRef.current = stream;
        //create session directory and save it

        let success = await createSessionDir();
        //if unable to create session_directory exit
        if (!success)
          return
        setLoading(false)
        setRecording(true);
        // setWebcamVisible(false);

        startRecordingAudio(audioStreamRef.current);
        console.log('starting recording audio')
        // Set a timeout to stop recording after 180 seconds
        timeoutRef.current = setTimeout(() => {
          setRecording(false);
          stopRecording();
        }, SESSION_TIMER * 1000);

        //call the function the 1st time
        audioTimeoutIDRef.current = setTimeout(sendAudio, 20000)  //15 seconds
      })
      .catch(err => console.log(err));

    // setLoading(true);
    // setResult(null);
  };
  // console.log('DAAAAAAAAAAAAAATE', sessionDate.current)
  const handleScreenshots = () => {

    // Send image every 2 seconds
    const sendImg = () => {
      const timestamp = new Date().getTime();

      // const img = camRef.current.getScreenshot().split(',')[1];
      const img = camRef.current.getScreenshot().split(',')[1]

      // console.log(img);
      const imageData = JSON.stringify({ image: img, timestamp: timestamp });
      uploadData(imageData, `${URL}/img/${sessionDate.current}/${tempDirRef.current}`);
      // setImgTimeoutID(setTimeout(sendImg, 2000))
      imgTimeoutIDRef.current = setTimeout(sendImg, 2000)

    }
    imgTimeoutIDRef.current = setTimeout(sendImg, 2000);
  }



  const stopRecording = useCallback(async () => {
    setRecording(false);
    clearTimeout(imgTimeoutIDRef.current)
    clearTimeout(audioTimeoutIDRef.current)
    clearInterval(countdownIntervalRef.current)
    clearTimeout(timeoutRef.current)  // timeout of the timer of 3 minutes
    setResponseLoading(true)
    // Stop the avatar audio
    // audioRef.current.pause();

    //sending last bit of audio
    console.log('sending last audio blob')
    if (audioRecorderRef.current) {
      try {
        const { blob } = await audioRecorderRef.current.stop()
        //  .then(async ({ blob }) => {
        // console.log('audio blob!', blob);
        const formData = new FormData();
        formData.append('audio', blob, `audio.wav`);
        // replacing uploaddata method with using fetch here
        const resultData = await fetch(`${URL}/upload_audio/${sessionDate.current}/${tempDirRef.current}`,
          {
            headers: {
              'Authorization': `Bearer ${user.accessToken}`
            },
            method: 'POST',
            cache: 'no-cache',
            body: formData,
          })

        if (!resultData.ok) {
          throw new Error('Network response was not ok', resultData.json());
        }


      }
      catch (error) {
        console.log('Error', error)
      }
    }


    // Cleanup function for audio stream and recorder
    if (audioRecorderRef.current) {
      audioRecorderRef.current.stop(); // Stop the recorder
      audioRecorderRef.current = null; // Release the recorder
    }
    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => {
        track.stop(); // Stop all the tracks in the received media stream
      });
      audioStreamRef.current = null; // Release the media stream
    }
    // If you're using an AudioContext, close it to release resources
    if (audioContextRef.current?.state === 'running') {
      audioContextRef.current.close();
    }

    try {

      console.log('fetching response')
      const response = await fetch(`${URL}/overview_response/${user.uid}/${sessionDate.current}/${tempDirRef.current}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify({
          "session_duration": SESSION_TIMER - countdown,
          "selected_topic": selectedTopic
        })
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // generate a unique session id local 
      const data = await response.json();


      //saving overview data to context
      setOverviewData(data);
      setSessionContextDate(getDateString())

      //saving overview to sessionstorage
      const currentSessionIdentifier = `${user.uid}-${getDateString()}`;
      const storageKey = `overviewData-${currentSessionIdentifier}`;
      sessionStorage.setItem(storageKey, JSON.stringify(data));

      // console.log('Unique ID Session Analysis', uniqueSessionID);

      navigate('/overview', { state: { tempDirRef: sessionIDUnique.current } });
      // setResponseLoading(false);
    } catch (error) {
      console.log('Error during upload process or fetching response:', error);
      setresultError(true);
      // setResponseLoading(false)
    }

  }, [countdown, selectedTopic]);

  useEffect(() => {
    if (recording) {
      startVisualizer(micCanvasRef.current)
      const timer = setTimeout(() => {
        setButtonDisabled(false);
        setCurrentQuestion("Don't know what to talk about? Click on 'Show a question' to start getting reflection questions to help you talk through the session.");

      }, 25000);
      return () => clearTimeout(timer);
    }
  }, [recording]);

  const toggleQuestions = async () => {
    const newShowQuestions = !showQuestions;
    setShowQuestions(newShowQuestions);
    if (newShowQuestions) {
      setCurrentQuestion(question);
      // if question is empty then set it to default
      if (question === '') {
        setCurrentQuestion("Not enough topics to formulate a question yet, You can talk for a bit more.");
      }
    } else {
      setCurrentQuestion(question);
    }
    setButtonDisabled(true);
    setLoadingProgress(0);

    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => prevProgress + 100 / 20);
    }, 1000);

    await new Promise(resolve => setTimeout(resolve, 25000));

    setButtonDisabled(false);
    clearInterval(interval);
    setLoadingProgress(0);
  };

  return (
    <div className="prevent_overflow">
      {loading ? (
        <LoadingSpinner className="bigger_height" />
      ) :
        responseLoading ? (
          <SessionResponseSpinner />)
          : (
            <>
              {selectedTopic && !recording && !loading && (
                <>
                  <Button onClick={() => setSelectedTopic(false)}
                    sx={{
                      display: "flex",
                      color: "primary.main",
                      alignItems: "center",
                      justifyContent: "start",
                      marginInline: "20px",
                      marginBottom: "10px",
                      gap: "10px",
                      width: "fit-content",
                      "&:hover": {
                        background: "transparent"
                      },
                    }}>
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" size="xl" />
                    <Typography alignSelf={"center"} variant="sb24Bold">Choose Topic</Typography>
                  </Button>
                  <MediaTest onContinue={startRecording} videoAccess={videoAccess} setVideoAccess={setVideoAccess} />
                </>
              )}
              {limitError && (
                <Dialog open={!!limitError} onClose={handleCloseLimitReachedModal}>
                  <DialogTitle>{limitError.reason}</DialogTitle>
                  <DialogContent>
                    <Typography>
                      {limitError.message}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseLimitReachedModal} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              )}


              {recording &&

                <Grid gap={5} marginBottom={"100px"} container display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                  <Grid md={7.5} lg={6.7} xl={5} xs={10} alignItems={"center"} border={"solid 1px #E3DBD5"} borderRadius={"10px"} justifyContent={"center"} sx={{ background: "#FFFFFF" }}>
                    <Grid md={7.5} lg={6.7} xl={5} xs={10} padding={"16px 24px 16px 24px"} borderRadius={"10px 10px 0px 0px"} sx={{ background: "#5C83BF" }} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                      <Typography color={"white"} variant="sb24Bold">Session in progress</Typography>
                      <Grid sx={{ '@media (max-width: 450px)': { display: "flex", flexDirection: "column", justifyContent: "center" } }} display={"flex"} gap={"10px"} alignItems={"center"}>
                        <div className="heart-container">
                          <RadioButtonCheckedIcon className="heart-icon" />
                        </div>
                        <Typography variant="sb20Bold" color={"white"}>
                          {formatTime(countdown)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid justifyContent={"center"} alignItems={"center"} padding={"20px 24px 20px 24px"} display={"flex"} flexDirection={"column"}>
                      <Typography my={1} variant="h32Bold" color={"#4C6EA2"} textAlign={"center"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        {currentQuestion}
                      </Typography>
                      <BarLoader
                        color={"#0e7bff"}
                        height={6}
                        width={130}
                        loading={buttonDisabled}
                        css={{
                          display: 'flex',
                          margin: 'auto',
                          borderRadius: '4px',
                          overflow: 'hidden',
                        }}
                      />
                      <button
                        onClick={toggleQuestions}
                        className={`my-3 fw-bold text-blue ${buttonDisabled ? 'disabled-question' : ''}`}
                        style={{ border: "none", background: "transparent", zIndex: 1, visibility: buttonDisabled ? "hidden" : "visible" }}
                        disabled={!recording || buttonDisabled}
                      >
                        Show a Question {showQuestions}
                      </button>

                      <Box ref={canvasParentRef} sx={{ position: "relative", height: "40vh", width: "50vw" }}>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>

                          <Box borderRadius={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{
                              position: "absolute",
                              top: "50%",
                              // top: "-84%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "200px", height: "200px",
                              backgroundColor: "#4C6EA2", border: "1.5px solid #91A4BC"
                            }}>
                            {/* <img src={WebcamVisible} /> */}
                            {(!webcamVisible || showAvatar) && (
                              <img src={Avatar} width={"95%"} style={{ position: "relative", bottom: "4px", borderRadius: "50%" }} alt="avatar" />
                            )}
                          </Box>
                          {videoAccess && (
                            <Webcam
                              onUserMedia={handleScreenshots}
                              onUserMediaError={() => {
                                console.log("Camera access blocked");
                                //stop sending video to backend
                                clearTimeout(imgTimeoutIDRef.current)
                              }}
                              audio={false}
                              ref={camRef}
                              screenshotFormat="image/jpeg"
                              style={{
                                zIndex: "1",
                                position: "absolute",
                                top: "50%",
                                // top: "-84%",
                                left: "50%",
                                transform: "translate(-50%, -50%)"

                              }}
                              className={`${webcamVisible ? "webcam-visible" : "webcam-invisible"}`}
                            />

                          )}

                          {/* Canvas for drawing circles around webcam */}
                          <canvas
                            ref={micCanvasRef}

                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              zIndex: "0",
                              background: "transparent", // Set background to transparent
                              filter: "drop-shadow(0px 5px 10px black)"
                            }}
                          ></canvas>

                        </Box>
                        {recording &&
                          <>
                            <div className="d-none justify-content-center align-items-center">
                              <canvas ref={audioCanvasRef} width={window.innerWidth}></canvas>
                              <audio ref={audioRef} src={audiofile} onLoadedMetadata={loadVisualizer}></audio>
                              <audio ref={audioEndingRef} src={audioending}></audio>
                            </div>

                          </>
                        }
                      </Box>
                      <Box bgcolor={WarningMessage ? "rgba(76, 110, 162, 0.1)" : "transparent"} alignSelf={"flex-end"} borderRadius={"8px"} padding={WarningMessage ? "12px" : "24px"} gap={"16px"}>
                        {WarningMessage ? (
                          <Typography color={"#4C6EA2"} variant="sb16Bold">{WarningMessage}</Typography>
                        ) : ("")}
                      </Box>
                    </Grid>
                    <Grid display={"flex"} md={7} lg={6} xl={5} xs={10} gap={"10px"} alignItems={"center"} justifyContent={"space-between"} padding={"16px 24px 16px 24px"} borderRadius={"0px 0px 10px 10px"} sx={{ background: "#5C83BF" }}>
                      <Grid sx={{ '@media (max-width: 450px)': { display: "flex", flexDirection: "column", justifyContent: "center" } }} display={"flex"} gap={"10px"} alignItems={"center"}>
                        <div className="heart-container">
                          <RadioButtonCheckedIcon className="heart-icon" />
                        </div>
                        <Typography variant="sb20Bold" color={"white"}>
                          {formatTime(countdown)}
                        </Typography>
                      </Grid>
                      <Grid display={"flex"} gap={"20px"} alignItems={"center"}>
                        {!overviewData && videoAccess && (
                          <Button
                            onClick={() => { setShowAvatar(!showAvatar); toggleWebcam() }}
                          >
                            {webcamVisible ?
                              <Grid>
                                <SvgIcon component={videoIcon} sx={{ width: "56px", height: "56px" }} inheritViewBox />
                              </Grid>
                              :
                              <Box width={"56px"} height={"56px"} padding={"16px"} borderRadius={"100%"} bgcolor={"white"}>
                                <FontAwesomeIcon style={{ width: "24px", height: "24px", color: "#718BB1" }} icon="fa-solid fa-video-slash" />
                              </Box>
                            }
                          </Button>
                        )}
                      </Grid>
                      <Grid>
                        <Button
                          variant="withIcon"
                          sx={{ border: "1.5px solid white" }}
                          onClick={() => {
                            if (!responseLoading) {
                              stopRecording();
                            }
                          }}
                          disabled={responseLoading}
                        >
                          <SvgIcon component={stopCircle} sx={{ width: "24px", height: "24px" }} inheritViewBox />
                          <Typography color={"white"} variant="sb16Bold">End Session</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
              {!selectedTopic && !recording && !loading && (
                <Grid gap={5} container display={"flex"} flexDirection={"column"} alignItems={"center"} mb={10} justifyContent={"center"}>
                  <Typography
                    variant="h32Bold"
                    color={"primary.darkerBlue"}
                    style={{ display: recording || loading ? "none" : "block" }}
                    className="text-center comfortaa_font"
                  >
                    {!resultError ?
                      'Before starting with your Session:' :
                      "Error: Analysis unclear. Please try again and wait for at least 15 seconds before stopping"
                    }
                  </Typography>
                  <Grid md={9} lg={7} xl={5} sm={11} xs={10} marginBottom={"50px"} border={"solid 1px #E3DBD5"} borderRadius={"10px"} justifyContent={"center"} sx={{ background: "#FFFFFF" }}>
                    <Grid md={9} lg={7} xl={5} sm={11} xs={10} padding={"16px 24px 16px 24px"} borderRadius={"10px 10px 0px 0px"} sx={{ background: "#5C83BF" }}>
                      <Typography color={"white"} variant="sb24Bold">Instructions</Typography>
                    </Grid>
                    <Grid gap={"65px"} padding={"42px 24px 36px 24px"} style={{ margin: "0%", display: showInstructions ? "block" : "none" }}>
                      <Typography color={"primary.darkerBlue"} variant="sb16Bold" display={"flex"} gap={"20px"} alignItems={"center"}>
                        <SvgIcon sx={{ width: "32px", height: "32px" }} component={Ellipse2} inheritViewBox />
                        Get ready to let it all out! Pick a topic, any topic, and start rambling. You've got 3 whole minutes to chatter about whatever comes to mind.
                      </Typography>
                      <Typography mt={"20px"} color={"primary.darkerBlue"} variant="sb16Bold" display={"flex"} gap={"20px"} alignItems={"center"}>
                        <SvgIcon sx={{ width: "32px", height: "32px" }} component={Ellipse2} inheritViewBox />
                        Go off on tangents, switch topics mid-sentence, talk about your cat - anything goes!                        </Typography>
                      <Typography mt={"20px"} color={"primary.darkerBlue"} variant="sb16Bold" display={"flex"} gap={"20px"} alignItems={"center"}>
                        <SvgIcon sx={{ width: "32px", height: "32px" }} component={Ellipse2} inheritViewBox />
                        If you get stuck, glance up for some thought-provokers by clicking on"Show a Question".
                      </Typography>
                      <Typography mt={"20px"} color={"primary.darkerBlue"} variant="sb16Bold" display={"flex"} gap={"20px"} alignItems={"center"}>
                        <SvgIcon sx={{ width: "32px", height: "32px" }} component={Ellipse2} inheritViewBox />
                        When time's up, it'll stop automatically, or you can end the session yourself. The more you talk, the better the analysis will be, so don't be shy! Have fun and say whatever you feel like sharing.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid md={9} lg={7} xl={5} sm={11} xs={10} borderRadius="10px" border="solid 1px #E3DBD5" sx={{ background: "#FFFFFF" }}>
                  <Grid md={9} lg={7} xl={5} sm={11} xs={10} padding="16px 24px 16px 24px" borderRadius="10px 10px 0px 0px" sx={{ background: "#5C83BF" }}>
                    <Typography color="white" variant="sb24Bold">Pick a topic you want to talk about</Typography>
                  </Grid>
                  <Grid 
                    md={9} lg={7} xl={5} sm={11} xs={10} 
                    padding="42px 24px 36px 24px" 
                    gap="35px" 
                    alignItems="center" 
                    display="flex" 
                    flexDirection={{ xs: "column", sm: "row" }} 
                    justifyContent={{ xs: "center", sm: "space-between" }}
                  >
                    {topics.map((topic, index) => (
                      <Grid
                        key={topic}
                        borderRadius="8px"
                        border="1px solid #E5E5E5"
                        padding="10px"
                        boxShadow="0px 5px 10px rgba(232, 224, 219, 0.5)"
                        display="flex"
                        md={7}
                        lg={6}
                        xl={5}
                        sm={3}
                        xs={10}
                        onClick={() => handleTopicClick(topic)}
                        alignItems="center"
                        gap="14px"
                        flexDirection="column"
                        className={`topic-option topic-option-${index}`}
                        sx={{
                          cursor: "pointer",
                          '&:hover': {
                            backgroundColor: "#FFF7F1",
                            border: "1px solid #E3DBD5"
                          }
                        }}
                      >
                        <img 
                          width={isMobile ? "75%" : "100%"} 
                          height="auto" 
                          style={{ objectFit: "cover" }} 
                          src={topicImages[topic]} 
                          alt={`${topic} illustration`} 
                        />
                        <Typography variant="sb20Bold" color="primary.darkerBlue">{topic}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}

    </div>
  );
};

export default MySession;
