import React, { useEffect, useMemo, useState } from "react";
import { Row, Dropdown, DropdownButton, Col  } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import texts from "../data/texts";
import "./style.scss";
import { v4 as uuidv4 } from "uuid";
import ApexChart from "./charts/ApexChart";
import { useUser } from "../contexts/UserContext";
import { URL } from "../Helpers";
import { faFaceFrown,
  faMeh, 
  faSmile, 
  faFaceSmileBeam, 
  faFaceGrinStars, 
  faBatteryEmpty, 
  faBatteryQuarter, 
  faBatteryHalf,
  faBatteryThreeQuarters,
  faBatteryFull
  } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "./shared/LoadingSpinner";
import "./charts/AdminView.scss"
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { startOfDay } from "date-fns";
import EmotionBarChart from "./charts/EmotionBarChart";
import { differenceInDays } from "date-fns";
import EmotionVerticalBarChart from "./charts/EmotionVerticalBarChart";
import { useNavigate } from "react-router-dom";
import EnergyAreaChart from "./charts/EnergyAreaChart";
import SessionsAreaChart from "./charts/SessionsAreaChart";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Import dayjs library
import { Grid, TextField, Button, Typography, SvgIcon, Select, MenuItem, InputLabel, FormControl, Box, useMediaQuery, useTheme  } from '@mui/material';
import { ReactComponent as Badge } from '../data/badge.svg';
import { styled } from '@mui/system';
import Carousel from "react-material-ui-carousel";
import level_1 from '../data/level_1.png';
import level_2 from '../data/level_2.png';
import level_3 from '../data/level_3.png';
import level_4 from '../data/level_4.png';
import level_5 from '../data/level_5.png';
import search from '../data/search_insights.png'
import psychology from '../data/psychology.png'
import mindful from '../data/mindfulness.png'
import mind from '../data/mind.png'
import pace from '../data/pace.png'

const CustomPicker = styled(DatePicker)(() => ({
  '& .MuiOutlinedInput-notchedOutline': {
    // Calculate color based on value
    border:"1px solid #3F444B"
  },
  '& .MuiInputBase-input' : {
    padding: "10px 12px 10px 12px"
  }
}));

const CustomSelect = styled(Select)(() => ({
  '& .MuiSelect-select': {
    padding: "10px 12px 10px 12px",
    border:"1px solid #3F444B"
  }
}));

const CustomCarousel = styled(Carousel)(() => ({
  '& .css-ktslay-MuiButtonBase-root-MuiIconButton-root': {
    position: "relative",
    top:"510px !important",
    backgroundColor: "transparent !important",
    color: "#4C6EA2",
    border: "2.5px solid #4C6EA2"
  }
}));

const AdminView = () => {
  const [sessionTimes, setSessionTimes] = useState([]);
  const [avgEnergyLevels, setAvgEnergyLevels] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDateCal, setEndDateCal] = useState(dayjs());
  const [startDateCal, setStartDateCal] = useState(dayjs());
  const [avgExtractedImprovedData, setAvgExtractedImprovedData] = useState([]);
  const [filteredEnergyLevels, setFilteredEnergyLevels] = useState([]);
  const [emotionChartData, setEmotionChartData] = useState([]);
  const [avgEmotionDataLine, setAvgEmotionDataLine] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sessionsCount, setSessionsCount] = useState("");
  const [emotionCountsChartData, setEmotionCountsChartData] = useState([]);
  const [sessionDurations, setSessionDurations] = useState([]);
  const [energyLevels, setEnergyLevels] = useState([]);
  const [showLineChart, setShowLineChart] = useState(true);
  const navigate = useNavigate();
  const [showSessionChart, setShowSessionChart] = useState(true);
  const [levelsByDates, setLevelsByDate] = useState({});
  const [topics, setTopics] = useState([]);
  const theme = useTheme();
  const [adminReport, setAdminReport] = useState([]);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("440px"));

  const StyledTextField = styled(TextField)({
    color: '#1565c0',
    borderRadius: '4px',
    borderColor: '#3F444B',
    border: '1px solid',
    backgroundColor: 'transparent',
  });

  useEffect(() => {
    const fetchData = async () => {
      const idToken = await user.getIdToken();

      try {
        const response = await fetch(`${URL}/get_users_sessions_by_company`, {
          headers: {
            "Authorization": `Bearer ${idToken}`
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log("all data", data);

        const sortedSessionTimes = (data.session_times || []).sort((a, b) => new Date(a) - new Date(b));
  
        const firstSessionTime = sortedSessionTimes.length > 0 ? sortedSessionTimes[0] : null;
        const lastSessionTime = sortedSessionTimes.length > 0 ? sortedSessionTimes[sortedSessionTimes.length - 1] : null;
  
        setStartDate(firstSessionTime ? new Date(firstSessionTime) : null);
        setEndDate(lastSessionTime ? new Date(lastSessionTime) : null);

        setStartDateCal(firstSessionTime ? dayjs(firstSessionTime) : null);
        setEndDateCal(lastSessionTime ? dayjs(lastSessionTime) : null);

        const uniqueDepartments = Array.from(new Set(data.departments || []));
        setDepartments(uniqueDepartments);
        setSessionTimes(sortedSessionTimes); 
        
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (!startDate && !endDate) {
      fetchData();
    } else {
      fetchDataByTime();
    }
  }, [user, URL, startDate, endDate, selectedDepartment]);
  
  const fetchDataByTime = async () => {
    try {
      setLoading(true);
      const formattedStartDate = startDate ? startDate.toISOString().split("T")[0] : "";
      const formattedEndDate = endDate ? endDate.toISOString().split("T")[0] : "";
  
      const departmentQueryParam = selectedDepartment ? `&department=${selectedDepartment}` : "";
      const idToken = await user.getIdToken();

      const response = await fetch(`${URL}/get_users_sessions_by_company?start_date=${formattedStartDate}&end_date=${formattedEndDate}${departmentQueryParam}`, {
        headers: {
          "Authorization": `Bearer ${idToken}`
        },
      });
      
      const data = await response.json();
      setSessionsCount(data.session_count || "");
      setSessionDurations(data.session_durations || []);
      setEnergyLevels(data.energy_levels || []);
      setTopics(data.session_topics || []);
      setAdminReport(data.admin_report || []);

      const sessionEnergyMapping = {};
      (data.session_times || []).forEach((sessionTime, index) => {
        const energyLevel = (data.energy_levels || [])[index];
        sessionEnergyMapping[sessionTime] = energyLevel;
      });
      setLevelsByDate(sessionEnergyMapping);

      if (data && data.session_times && data.improved_data) {
        const filteredSessionTimes = data.session_times.filter(sessionTime => {
          const sessionDate = new Date(sessionTime);
          return sessionDate >= startDate && sessionDate <= endDate;
        });

        const extractedImprovedData = [];

        for (let i = 0; i < filteredSessionTimes.length; i++) {
          const sessionTime = filteredSessionTimes[i];

          if (data.improved_data && data.session_times.includes(sessionTime)) {
            const dataIndex = data.session_times.indexOf(sessionTime);

            if (data.improved_data[dataIndex]) {
              const emotion = data.improved_data[dataIndex][0];
              const value = data.improved_data[dataIndex][1];

              if (emotion && value) {
                extractedImprovedData.push([sessionTime, emotion, value]);
              } else {
                extractedImprovedData.push([sessionTime, "", ""]);
              }
            } else {
              console.log("No improved data for sessionTime:", sessionTime);
            }
          } else {
            console.log("No improved data for sessionTime:", sessionTime);
          }
        }
  
        const avgEmotionValues = {};
        const avgEmotionCounts = {};
        const emotionCounts = {};

        extractedImprovedData.forEach(([sessionTime, emotion, value]) => {
          const dayKey = new Date(sessionTime).toISOString().split("T")[0];
  
          if (!avgEmotionValues[dayKey]) {
            avgEmotionValues[dayKey] = {};
            avgEmotionCounts[dayKey] = {};
          }
  
          if (emotion !== "There is no previous session") {
            if (!avgEmotionValues[dayKey][emotion]) {
              avgEmotionValues[dayKey][emotion] = 0;
            }
  
            avgEmotionValues[dayKey][emotion] += parseFloat(value);
  
            avgEmotionCounts[dayKey][emotion] = (avgEmotionCounts[dayKey][emotion] || 0) + 1;
            
            emotionCounts[emotion] = (emotionCounts[emotion] || 0) + 1;
          }
        });

        const emotionCountsArray = Object.entries(emotionCounts)
          .map(([emotion, count]) => ({ emotion, count }));

        emotionCountsArray.sort((a, b) => b.count - a.count);
        setEmotionCountsChartData(emotionCountsArray);

        const avgEmotionAverages = {};
  
        Object.keys(avgEmotionValues).forEach(dayKey => {
          avgEmotionAverages[dayKey] = {};
  
          Object.keys(avgEmotionValues[dayKey]).forEach(emotion => {
            const averageValue = avgEmotionValues[dayKey][emotion] / avgEmotionCounts[dayKey][emotion];
            avgEmotionAverages[dayKey][emotion] = parseFloat(averageValue.toFixed(2));
          });
        });
  
        const avgEmotionData = Object.entries(avgEmotionAverages)
          .filter(([dayKey, emotions]) => {
            const hasEmotionValues = Object.values(emotions).some(value => value !== undefined);
            return hasEmotionValues;
          })
          .map(([dayKey, emotions]) => ({
            dayKey,
            ...emotions
          }));

        setAvgEmotionDataLine(avgEmotionData);

        const filteredEnergys = (data.energy_levels || []).filter((energyLevel, index) => {
          const sessionTime = data.session_times[index];
          const sessionDate = new Date(sessionTime);
          return sessionDate >= startDate && sessionDate <= endDate;
        });

        setFilteredEnergyLevels(filteredEnergys);

      } else {
        console.error("Error: Data or session_times is undefined or empty");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const topicCounts = {};
  let totalTopics = 0;
  
  // Step 1: Count topics
  if (Array.isArray(topics) && topics.length > 0) {
    topics.forEach(topic => {
      if (topic) {  // Check if topic is not null or undefined
        topicCounts[topic] = (topicCounts[topic] || 0) + 1;
        totalTopics++;
      }
    });
  } else {
    console.warn('Topics is not a valid array or is empty');
  }
  
  // Step 2: Calculate ratio of each topic
  const topicRatios = {};
  const topicPercentages = {};
  let maxPercentage = 0;
  let maxTopic = "";
  
  if (totalTopics > 0) {
    Object.keys(topicCounts).forEach(topic => {
      const count = topicCounts[topic];
      const ratio = count / totalTopics;
      topicRatios[topic] = ratio;
      
      // Calculate percentage
      const percentage = ratio * 100;
      topicPercentages[topic] = percentage;
  
      // Track maximum percentage
      if (percentage > maxPercentage) {
        maxPercentage = percentage;
        maxTopic = topic;
      }
    });
  } else {
    console.warn('No valid topics found');
  }
  
  Object.keys(topicRatios).forEach(topic => {
      const ratio = topicRatios[topic];
      const percentage = (ratio * 100).toFixed(2);
      topicPercentages[topic] = percentage + "%";
  
      if (parseFloat(percentage) > maxPercentage) {
          maxPercentage = parseFloat(percentage);
          maxTopic = topic;
      }
  });
  
  console.log("Topic Ratios:", topicPercentages);
  console.log(topicPercentages);
  
  
  const handleAddEmployeeClick = () => {
    navigate("/add-employee");
  };
  
  const handleShowReport = () => {
    if (adminReport) {
      navigate("/wellbeing-report", { state: { adminReport } });
    } else {
      // Handle case when adminReport is not available
      console.log("Admin report not available");
    }
  };
  const toggleChart = () => {
    setShowLineChart(!showLineChart);
  };
  
const numericFilteredEnergyLevels = filteredEnergyLevels.map(value => Number(value));

const totalFilteredEnergyLevels = numericFilteredEnergyLevels.length;

const energyLevelCounts = Array.from({ length: 5 }).fill(0);
const energyLevelPercentages = Array.from({ length: 5 }).fill(0);

numericFilteredEnergyLevels.forEach(value => {
  energyLevelCounts[value - 1]++;
});

energyLevelCounts.forEach((count, index) => {
  energyLevelPercentages[index] = ((count / totalFilteredEnergyLevels) * 100).toFixed(2);
});

  // Calculate days between start and end date
  const daysBetweenSessions = startDateCal && endDateCal
    ? endDateCal.diff(startDateCal, 'day') // Calculate difference in days
    : 0;

  let totalDuration = 0;

  for (let i = 0; i < sessionDurations.length; i++) {
    totalDuration += sessionDurations[i];
  }

  const avgSessionDuration = Math.round(totalDuration / sessionDurations.length);

  let durationText = "";
  if (totalDuration < 60) {
    durationText = `${totalDuration} secs`;
  } else {
    const totalDurationInMinutes = totalDuration / 60;
    durationText = `${totalDurationInMinutes.toFixed(1)} mins`;
  }


  useEffect(() => {
    fetchDataByTime();
  }, [startDate, endDate, selectedDepartment]);

  useEffect(() => {
    const processedChartData = processEmotionChartData(avgEmotionDataLine);
    setEmotionChartData(processedChartData);
    setCategories(processedChartData.map(item => item.x));
  }, [avgEmotionDataLine]);
  
  const processEmotionChartData = (data) => {
    const chartData = [];
  
    const uniqueDays = new Set(data.map(item => item.dayKey));
  
    uniqueDays.forEach(dayKey => {
      const entriesForDay = data.filter(item => item.dayKey === dayKey);
  
      const aggregatedEmotions = entriesForDay.reduce((acc, entry) => {
        Object.keys(entry).forEach(emotion => {
          if (emotion !== "dayKey") {
            acc[emotion] = (acc[emotion] || 0) + entry[emotion];
          }
        });
        return acc;
      }, {});
  
      chartData.push({
        x: dayKey,
        ...aggregatedEmotions,
      });
    });
  
    return chartData;
  };
  
  useEffect(() => {
    if (filteredEnergyLevels) {
      const sum = filteredEnergyLevels.reduce((acc, value) => acc + value, 0);
      const avgEnergys = sum / filteredEnergyLevels.length;
      const avgEnergyLevels = avgEnergys.toFixed(1);
      setAvgEnergyLevels(avgEnergyLevels);
      
    } else {
      console.error("Error: enegylevels is undefined or empty");
    }
  }, [filteredEnergyLevels]);

  const handleDepartmentChange = (selectedValue) => {
    // Check if "All Departments" is selected
    if (selectedValue === "All Departments") {
      setSelectedDepartment(null); // Set to null or any other suitable value
    } else {
      setSelectedDepartment(selectedValue);
    }
    fetchDataByTime();
  };
  
  const EnergyBoxes = useMemo(() => {
    return Array.from({ length: 5 });
  }, []);

  const toggleChartView = () => {
    setShowSessionChart(!showSessionChart);
  };

  const processImprovedEmotionsData = (extractedImprovedData) => {
    const emotionCounts = {};
    const emotionAverages = {};

    extractedImprovedData.forEach(([emotion, value]) => {
      if (emotion !== "There is no previous session") {
        const percentValue = parseFloat(value);
        emotionAverages[emotion] = (emotionAverages[emotion] || 0) + percentValue;
      }
    });
    
    const mostCommonEmotion = Object.keys(emotionCounts).reduce((a, b) =>
    emotionCounts[a] > emotionCounts[b] ? a : b
    
    );
    return {
      emotionAverages,
      mostCommonEmotion,
      mostCommonEmotionAverage: emotionAverages[mostCommonEmotion],
    };
  };

  const { emotionAverages, mostCommonEmotion, mostCommonEmotionAverage } = useMemo(() => {

    if (avgExtractedImprovedData.length === 0) {
      return {
        emotionAverages: {},
        mostCommonEmotion: null,
        mostCommonEmotionAverage: null,
      };
    }

    return processImprovedEmotionsData(avgExtractedImprovedData);
  }, [avgExtractedImprovedData]);

  const emotionCounts = {};
  avgExtractedImprovedData.forEach(([sessionTime, emotion]) => {
    emotionCounts[emotion] = (emotionCounts[emotion] || 0) + 1;
  });

  const emotionAveragesArray = Object.keys(emotionAverages)
    .filter((emotion) => emotionAverages[emotion] !== 0)
    .map((emotion) => ({
      emotion,
      average: emotionAverages[emotion],
      count: emotionCounts[emotion],
    }));

    const sortedEmotionsArray = emotionAveragesArray
    .filter(item => item.emotion)
    .sort((a, b) => b.count - a.count);

  const sortedEmotions = sortedEmotionsArray.map((item) => item.emotion);
  const mostCommonEmotionSeries = [mostCommonEmotionAverage];
  const sortedEmotionsWithoutMostCommon = sortedEmotions.filter(
    (emotion) => emotion !== mostCommonEmotion
  );

  const seriesWithoutMostCommon = sortedEmotionsWithoutMostCommon.map(
    (emotion) => emotionAverages[emotion]
  );

  const options = {
    chart: {
      height: 350,
      type: "line",
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    },
    title: {
      text: "Average Emotion Values",
      align: "left",
    },
  };

  const {
    adminTitle,
    avgEnergyLevelHeader,
    avgEnergyLevelDescription,
    improvedEmotionsHeader,
    improvedEmotionsDescription,
    topicsTitle,
    topicsDescription,
    sessionsTitle,
    sessionsDescription,
    energyPerDayTitle,
    energyPerDayDescription,
    scoreTitle,
    scoreDescription
  } = texts.adminView;

  // const getBoxStyles = (index) => {
  //   const opacity = 1;
  //   const gradientColors = [
  //     "rgb(167 214 255)", "rgb(210 212 255)", "rgb(234 200 255)", "rgb(237 179 255)", "rgb(247 166 255)"
  //   ];
  //   const isSmallScreen = window.innerWidth < 475;
  //   const width = isSmallScreen ? "5rem" : "7rem";
  //   const height = isSmallScreen ? "7rem" : "9rem";
  //   const fontSize = isSmallScreen ? "12px" : "16px";
  //   return {
  //     justifyContent: "center",
  //     alignItems: "center",
  //     fontSize: fontSize,
  //     opacity: opacity,
  //     background: index < gradientColors.length ? `linear-gradient(to bottom, ${gradientColors[index]}, rgb(99 192 255))` : "",
  //     border: "1px solid black",
  //     width: width,
  //     height: height,
  //     padding: "0.5rem",
  //     color: "rgb(84, 84, 84)"
  //   };
  // };

  return (
    <div className="prevent_overflow">
      {loading ? (
        <LoadingSpinner className="bigger_height" />
      ) : (
        <>
            <Grid container gap={"60px"} justifyContent={"center"} alignItems={"center"}>
              <Grid xs={12} display={"flex"} flexDirection={{xs:"column", md:"row"}} alignItems={"center"} justifyContent={"space-between"} className="to-filter">
              <div className="d-flex justify-content-end">
                <Button sx={{'&:hover': {backgroundColor:"#446494", color:"white"}}} variant="primary" onClick={handleAddEmployeeClick}>
                  <SvgIcon component={Badge}/>
                  <Typography variant="sb16Bolder">Add Employee</Typography>
                </Button>
                {/* space between buttons */}
                <div style={{width:"20px"}}></div>

                <Button sx={{'&:hover': {backgroundColor:"#446494", color:"white"}}} variant="primary" onClick={handleShowReport}>
                  <SvgIcon component={Badge}/>
                  <Typography variant="sb16Bolder">Show Report</Typography>
                </Button>
              </div>
              <Grid display={"flex"} flexDirection={{xs:"column", sm:"row"}} alignItems={"center"} className="to-filter">
                <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <CustomPicker
                      label="From"
                      onChange = {(date) => {setStartDate(date); setStartDateCal(dayjs(date))}}
                      defaultValue={dayjs(startDate)}
                      // placeholderText={startDate ? undefined : "Start Date"}
                      className="datepicker-input"
                      format="DD/MM/YYYY"
                      minDate={dayjs(new Date(sessionTimes[0]))}
                      maxDate={dayjs(new Date())}
                      slots={{
                        TextField: StyledTextField,
                      }} 
                    />
                    <CustomPicker
                      label="To"
                      onChange = {(date) => {setEndDate(date); setEndDateCal(dayjs(date))}}
                      defaultValue={dayjs(endDate)}
                      // placeholderText="End Date"
                      className="datepicker-input"
                      format="DD/MM/YYYY"
                      minDate={dayjs(new Date(startDate))}
                      maxDate={dayjs(new Date())}
                      slots={{
                        TextField: StyledTextField,
                      }} 
                    />
                  </DemoContainer>
                </LocalizationProvider>
                  {/* <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText={startDate ? undefined : "Start Date"}
                    className="datepicker-input"
                    minDate={startOfDay(new Date(sessionTimes[0]))}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                  />
                  <Typography color={"#4C6EA2"} variant="body2SemiBold">
                    To:
                  </Typography>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="End Date"
                    className="datepicker-input"
                    minDate={startOfDay(new Date(sessionTimes[0]))} 
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                  /> */}
                </Grid>
                <Grid width={"250px"}>
                  <FormControl fullWidth>
                  <CustomSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedDepartment ? selectedDepartment : "All Departments"}
                    onChange={(event) => handleDepartmentChange(event.target.value)}
                    sx={{marginTop:"5px"}}
                  >
                    <MenuItem value="All Departments" fontSize={"16px"} fontWeight={"600"}>
                      All Departments
                    </MenuItem>
                    {departments?.map((department) => (
                      <MenuItem key={department} value={department} fontSize={"16px"} fontWeight={"600"}>
                        {department}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  </FormControl>
                </Grid>
{/* 
                <DropdownButton
                  title={`Department: ${selectedDepartment || "All"}`}
                  onSelect={handleDepartmentChange}
                  className="dropdown-department"
                >
                  <Dropdown.Item eventKey={null} className="dropitem-department">
                    All
                  </Dropdown.Item>
                  {departments?.map((department) => (
                    <Dropdown.Item key={department} eventKey={department} className="dropitem-department">
                      {department}
                    </Dropdown.Item>
                  ))}
                </DropdownButton> */}
              </Grid>
              </Grid>
                <Grid xs={11} className="admin-card">
                    <Grid padding={"16px 24px 0px 24px"}>
                      <Typography color={"#4C6EA2"} variant="sb24Bold">Overview</Typography>
                    </Grid>
                    <Grid display={"flex"} flexDirection={{xs: "column", md:"row"}} gap={{xs:"38px", sm:"20px"}} justifyContent={"space-around"} alignItems={"center"}  padding={"42px 24px 36px 24px"}>
                      {/* <Grid display={"flex"} flexDirection={{xs: "column", sm:"row"}} gap={{xs:"38px", sm:"20px"}}> */}
                        <Grid textAlign={"center"} display={"flex"} flexDirection={"column"} gap={"15px"}>
                          <Grid display={"flex"} alignItems={"center"} gap={"10px"} justifyContent={"center"}>
                            <img src={search}/>
                            <Typography color={"#4C6EA2"}  variant="h32Bold">{avgSessionDuration} s</Typography>
                          </Grid>
                          <Typography variant="sb20Regular" color={"#3f444bcf"} >Average Session</Typography>
                        </Grid>
                        <Grid display={{xs:"none", md:"block"}} className="vertical-line" style={{background:"#E3DBD5", height:"5rem"}} />
                        <Grid  textAlign={"center"} display={"flex"} flexDirection={"column"} gap={"15px"}>
                          <Grid display={"flex"} alignItems={"center"} gap={"10px"} justifyContent={"center"}>
                            <img src={mind}/>
                            <Typography color={"#4C6EA2"} variant="h32Bold" paddingTop={"3px"}>{daysBetweenSessions} days</Typography>
                          </Grid>
                          <Typography variant="sb20Regular" color={"#3f444bcf"} >Mindful Days</Typography>
                        </Grid>
                        <Grid display={{xs:"none", md:"block"}} className="vertical-line" style={{background:"#E3DBD5", height:"5rem"}} />
                      {/* </Grid> */}
                      {/* <Grid display={"flex"} flexDirection={{xs: "column", sm:"row"}} gap={{xs:"38px", sm:"20px"}}> */}
                        <Grid textAlign={"center"} display={"flex"} flexDirection={"column"} gap={"15px"}>
                          <Grid Grid display={"flex"} alignItems={"center"} gap={"10px"} justifyContent={"center"}>
                            <img src={psychology}/>
                            <Typography color={"#4C6EA2"} variant="h32Bold" paddingTop={"3px"}>{sessionsCount}</Typography>
                          </Grid>
                          <Typography variant="sb20Regular" color={"#3f444bcf"} >Total Sessions</Typography>
                        </Grid>
                        <Grid display={{xs:"none", md:"block"}} className="vertical-line" style={{background:"#E3DBD5", height:"5rem"}} />
                        <Grid textAlign={"center"} display={"flex"} flexDirection={"column"} gap={"15px"}>
                          <Grid display={"flex"} alignItems={"center"} gap={"10px"} justifyContent={"center"}>
                            <img src={pace}/>
                            <Typography color={"#4C6EA2"} variant="h32Bold" paddingTop={"3px"}>{durationText}</Typography>
                          </Grid>
                          <Typography variant="sb20Regular" color={"#3f444bcf"} >Mindful Minutes</Typography>
                        </Grid>
                        <Grid display={{xs:"none", md:"block"}} className="vertical-line" style={{background:"#E3DBD5", height:"5rem"}} />
                      {/* </Grid> */}
                      {/* <Grid display={"flex"} gap={"20px"}> */}
                        <Grid textAlign={"center"} display={"flex"} flexDirection={"column"} gap={"15px"}>
                          <Grid display={"flex"} alignItems={"center"} gap={"10px"} justifyContent={"center"}>
                            <img src={mindful}/> {/* Assuming mindful is the image source */}
                            <Typography color={"#4C6EA2"} variant="h32Bold" paddingTop={"3px"}>
                              {maxTopic} {topicPercentages[maxTopic]} {/* Displaying the topic with its percentage */}
                            </Typography>
                          </Grid>
                          <Typography variant="sb20Regular" color={"##3f444bcf"} >
                            The Top Topic
                          </Typography>
                        </Grid>
                      {/* </Grid> */}
                    </Grid>
                </Grid>
                <Grid container xs={11} justifyContent={"space-between"} gap={{xs:"40px", md:"0px"}}>
              <Grid lg={8} md={7.5} xs={12} className="admin-card">
                <CustomCarousel>
                  <Grid item>
                    <Grid padding={"16px 24px 0px 24px"}>
                      <Typography color={"#4C6EA2"} variant="sb24Bold">{sessionsTitle}</Typography>
                    </Grid>
                    <Grid className="admin-cardContent">
                      <div className="mb-4">
                        <SessionsAreaChart sessionTimes={sessionTimes} startDate={startDate} endDate={endDate} daysBetweenSessions={daysBetweenSessions} />
                      </div>
                      <Grid display={"flex"} alignItems={"center"} gap={"6px"} sx={{placeContent:"center"}}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        style={{ color: "#005eb1" }}
                        size="sm" />
                      <Typography color={"#4C6EA2"} variant="body2SemiBold"> {sessionsDescription}</Typography>
                    </Grid>
                    </Grid>

                  </Grid>

                  <Grid item>
                    <Grid padding={"16px 24px 0px 24px"}>
                      <Typography color={"#4C6EA2"} variant="sb24Bold">{energyPerDayTitle}</Typography>
                    </Grid>
                    <Grid className="admin-cardContent">
                      <div className="mb-4">
                        <EnergyAreaChart levelsByDates={levelsByDates} startDate={startDate} endDate={endDate} daysBetweenSessions={daysBetweenSessions} />
                      </div>
                      <Grid display={"flex"} alignItems={"center"} gap={"6px"} sx={{placeContent:"center"}}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        style={{ color: "#005eb1" }}
                        size="sm" />
                      <Typography color={"#4C6EA2"} variant="body2SemiBold"> {energyPerDayDescription}</Typography>
                    </Grid>
                    </Grid>
                  </Grid>
                </CustomCarousel>

              </Grid>
              <Grid lg={3.5} md={4} xs={12} className="admin-card">
                <Grid padding={"16px 24px 0px 24px"}>
                    <Typography color={"#4C6EA2"} variant="sb24Bold">{topicsTitle}</Typography>
                </Grid>
                <Grid display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={"32px"} padding={{md:"42px 0px 42px 0px"}} className="admin-cardContent">
                      <ApexChart topicPercentages = {topicPercentages}/>  
                      <Grid display={"flex"} alignItems={"center"} gap={"6px"} sx={{placeContent:"center"}}>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          style={{ color: "#005eb1" }}
                          size="sm" />
                        <Typography color={"#4C6EA2"} variant="body2SemiBold"> {topicsDescription}</Typography>
                      </Grid>
                  </Grid>
              </Grid>
            </Grid>
                <Grid container xs={11} display={"flex"} justifyContent={"space-between"} gap={{xs:"40px", md:"0px"}}>
                  <Grid className="admin-card" lg={6} md={6.5} xs={12}>
                    <Grid padding={"16px 24px 0px 24px"}>
                      <Typography color={"#4C6EA2"} variant="sb24Bold">{improvedEmotionsHeader}</Typography>
                    </Grid>
                    <Grid display={"flex"} flexDirection={"column"} justifyContent={"center"} className="admin-cardContent">
                      {mostCommonEmotion ? (
                        <div className="text-center mt-4">
                          <Typography color={"#4C6EA2"} variant="h32Bold" paddingTop={"3px"}>No improved emotions data available.</Typography>
                        </div>
                      ) : (
                        <div>
                                            <div className="d-flex justify-content-center p-2">
                      <EmotionBarChart emotionCountsChartData={emotionCountsChartData} />
                    </div>

                        </div>
                      )}
                    <Grid display={"flex"} alignItems={"center"} gap={"6px"} sx={{placeContent:"center"}}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        style={{ color: "#005eb1" }}
                        size="sm" />
                      <Typography color={"#4C6EA2"} variant="body2SemiBold"> {improvedEmotionsDescription}</Typography>
                    </Grid>
                  </Grid>
                  </Grid>
                  <Grid className="admin-card" lg={5.5} md={5} xs={12}>
                    <Grid padding={"16px 24px 0px 24px"}>
                      <Typography color={"#4C6EA2"} variant="sb24Bold">Improved Score</Typography>
                    </Grid>
                    <Grid display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} className="admin-cardContent">
                      
                      <EmotionVerticalBarChart emotionCountsChartData={emotionCountsChartData} avgEmotionDataLine={avgEmotionDataLine} />

                    <Grid display={"flex"} alignItems={"center"} gap={"6px"} sx={{placeContent:"center"}}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        style={{ color: "#005eb1" }}
                        size="sm" />
                      <Typography color={"#4C6EA2"} variant="body2SemiBold"> {scoreDescription}</Typography>
                    </Grid>
                    </Grid>

                  </Grid>
                </Grid>
                <Grid xs={11} className="admin-card" mb={5}>
                  <Grid padding={"16px 24px 0px 24px"}>
                    <Typography color={"#4C6EA2"} variant="sb24Bold">Overview</Typography>
                  </Grid>
                    <Grid xs={12} display={"flex"} flexDirection={"column"} justifyContent={"center"} className="admin-cardContent">
                    <Grid gap={{xs:"20px", sm:"0px", md:"20px", lg:"30px"}} display={"flex"} flexDirection={{xs:"column", sm:"row"}} justifyContent={"center"} alignItems={"center"}>
                    {energyLevelCounts.map((count, index) => (
                      <>
                      <Grid height={{xs:"1px", sm:"5rem"}} width={{xs:"10rem", sm:"1px"}} className="vertical-line" style={{ background: "#E3DBD5", display: index === 0 ? "none" : "flex" }} />
                      <Box key={uuidv4()} bgcolor={"#FFF7F1"} gap={"12px"} textAlign={"center"} borderRadius={"4px"} border={"1px solid #E3DBD5"}  padding={isSmallScreen ? "5px 7px" : "20px 32px"}>
                        {index === 0 && <img src={level_1} width={"100%"} height={"100%"}/>}
                        {index === 1 && <img src={level_2} width={"100%"} height={"100%"}/>}
                        {index === 2 && <img src={level_3} width={"100%"} height={"100%"}/>}
                        {index === 3 && <img src={level_4} width={"100%"} height={"100%"}/>}
                        {index === 4 && <img src={level_5} width={"100%"} height={"100%"}/>}
                        <div className="mt-3 d-flex flex-column">
                            {/* <span>Total: {count}</span> */}
                            <span className="fw-bold">{energyLevelPercentages[index]}%</span>
                          </div>
                      </Box>
                      </>
                    ))}
                    </Grid>
                    <Grid display={"flex"} alignItems={"center"} gap={"6px"} sx={{placeContent:"center"}}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        style={{ color: "#005eb1" }}
                        size="sm" />
                      <Typography color={"#4C6EA2"} variant="body2SemiBold"> {avgEnergyLevelDescription}</Typography>
                    </Grid>
                    </Grid>
                </Grid>
              </Grid>
          </>
      )}
    </div>
  );
};

export default AdminView;
