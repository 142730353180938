import React, { useState, createContext, useContext, useEffect, useCallback } from 'react';
import { onAuthStateChanged, getAdditionalUserInfo } from "firebase/auth";
import { auth } from '../Firebase';
import LoadingSpinner from '../pages/shared/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { fetchUserRole, createNewUserDocument } from '../Helpers';
import "../pages/style.scss";





const UserContext = createContext();

// In UserProvider
export const UserProvider = ({ children }) => {
  const initialRole = localStorage.getItem('role');
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(initialRole); // Use role from local storage
  const [tier, setTier] = useState(null)
  const [name, setName] = useState(null);
  const [premium_status, setPremium_status] = useState(null)
  const [stripe_customer_id, setStripe_customer_id] = useState(null);
  const [stripe_subscription_id,setStripe_subscription_id] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const navigate = useNavigate();
  // Function to refresh user claims
  const refreshUserClaims = useCallback(async (user) => {
    try {
      const token = await user.getIdTokenResult(true);//force auth token refresh
      setRole(token.claims.role); // Update the role based on the new claims
      setTier(token.claims.tier || "free")
      setName(token.claims.name || null)
      setPremium_status(token.claims.premium_status || null)
      setStripe_customer_id(token.claims.stripe_customer_id || null)
      setStripe_subscription_id(token.claims.stripe_subscription_id || null)
    } catch (error) {
      console.error('Failed to refresh user claims:', error);
    }
  }, []);

  //function to set user custom claims
  const setUserClaims = useCallback(async (user) => {
    try {
      const token = await user.getIdTokenResult();
      setRole(token.claims.role || "user"); // Update the role based on the new claims
      setTier(token.claims.tier || "free")
      setName(token.claims.name || null)
      setPremium_status(token.claims.premium_status || null)
      setStripe_customer_id(token.claims.stripe_customer_id || null)
      setStripe_subscription_id(token.claims.stripe_subscription_id || null)
    } catch (error) {
      console.error('Failed to set user claims:', error);
    }
  }, []);

  const clearClaims = useCallback(async () => {
    setRole(null);
    setTier(null)
    setName(null)
    setPremium_status(null)
    setStripe_customer_id(null)
    setStripe_subscription_id(null)
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      try {
        if (!user) {
          // console.log('no user')
          setUser(null);
          clearClaims()
          // navigate('/');//moved to protected route to handle that
        } else {
          setUser(user);
          setUserClaims(user)
        }
      } catch (error) {
        console.error('Failed to fetch user and claims:', error);
      }
      setIsLoading(false);
    });

    return unsubscribe;
  }, [setUser, setRole, setIsLoading,setUserClaims,clearClaims]);

  //wait till onauthstatechanged finishes processing
  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
        className='prevent_overflow'
      >
        <LoadingSpinner />
      </div>
    );
  }




  return (
    <UserContext.Provider value={{ user, setUser, role, setRole, tier,name, refreshUserClaims, premium_status, stripe_customer_id, stripe_subscription_id}}>
      {children}
    </UserContext.Provider>
  );

};

export default UserContext;

export const useUser = () => {
  return useContext(UserContext);
};
